export function hexToRgba(hexCode: string, opacity = 1): string {
  // fallback color
  if (!hexCode) return "black";

  // Remove the # symbol from the hex code
  hexCode = hexCode.replace("#", "");

  // If the hex code is short, expand it to the full 6-digit format
  if (hexCode.length === 3) {
    hexCode = `${hexCode[0]}${hexCode[0]}${hexCode[1]}${hexCode[1]}${hexCode[2]}${hexCode[2]}`;
  }

  // Parse the hex code into RGB values
  const r = parseInt(hexCode.substring(0, 2), 16);
  const g = parseInt(hexCode.substring(2, 4), 16);
  const b = parseInt(hexCode.substring(4, 6), 16);

  // Calculate the opacity value
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  // Return the RGBA color string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export const validateEmail = (email: string) => {
  if (email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  } else {
    return false;
  }
};

export const validatePass = (pass: string) => {
  if (pass.length < 8) {
    return "Password should be 8 characters long.";
  } else if (!containsUppercase(pass)) {
    return "Password should contain at least one uppercase letter.";
  } else if (!hasLowercase(pass)) {
    return "Password should contain at least one lowercase letter.";
  } else if (!containsNumber(pass)) {
    return "Password should contain at least one number.";
  } else if (!containsSpecialCharacter(pass)) {
    return "Password should contain at least one special character.";
  } else {
    return "";
  }
};

export function containsUppercase(str: string) {
  for (let i = 0; i < str.length; i++) {
    if (str[i] !== str[i].toLowerCase()) {
      return true; // Character at index i is an uppercase letter
    }
  }
  return false; // No uppercase letters found in the string
}

export function hasLowercase(str: string) {
  // Define a regular expression pattern to match lowercase letters
  var lowercasePattern = /[a-z]/;

  // Iterate through each character in the string
  for (var i = 0; i < str.length; i++) {
    // Check if the current character matches the lowercase pattern
    if (lowercasePattern.test(str[i])) {
      return true; // If a lowercase letter is found, return true
    }
  }

  // If no lowercase letter is found, return false
  return false;
}

export function containsNumber(str: string) {
  const regex = /\d/; // Regex to matche any digit (number)
  return regex.test(str);
}

export function containsSpecialCharacter(str: string) {
  const regex = /[^a-zA-Z0-9]/; // Regex to match any character that is not a letter or a number
  return regex.test(str);
}

export const truncateString = (str: string, maxLength: number) => {
  if (str?.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  }
  return str;
};
