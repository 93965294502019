import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Text,
  useToast,
} from "@chakra-ui/react";
import PdfPreview from "shared/PdfPreview";

import { FiDownload } from "react-icons/fi";
import { CiCircleCheck } from "react-icons/ci";
import { RiExternalLinkLine } from "react-icons/ri";
import Spinner from "components/ui/SpinnerDots";

export interface CurrentViewProps {
  setCurrentView: (value: string) => void;
}

export default function Relations({ setCurrentView }: CurrentViewProps) {
  const _downloaded = localStorage.getItem("formDownloaded");
  const downloaded = _downloaded ? JSON.parse(_downloaded) : false;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [actionText, setActionText] = useState(
    downloaded ? "Redownload" : "Download"
  );

  // Hooks
  const toast = useToast();

  const formURL =
    "https://empathicai.s3.eu-central-1.amazonaws.com/forms/Investment_Funds_Form.pdf";

  const downloadForm = async () => {
    try {
      setIsDownloading(true);
      const response = await fetch(formURL);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "empathicai_investor_form.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      localStorage.setItem("formDownloaded", JSON.stringify(true));
      setActionText("Redownload");
      setIsDownloading(false);
    } catch (error) {
      console.error("Error downloading the form:", error);
      toast({
        description: "Download failed. Try again",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      setIsDownloading(false);
    }
  };

  return (
    <Box>
      <Heading
        as={"h2"}
        fontFamily="Poppins, sans-serif"
        lineHeight="1.3"
        fontSize={{ lg: "22px", xl: "26px", "2xl": "34px" }}
        w={"100%"}
        display={"flex"}
        mb={6}
      >
        <Text
          bgGradient="linear(to right, rgb(31.51, 32.14, 82.55) 0%, rgb(32, 136, 190) 100%)"
          bgClip="text"
          color="transparent"
        >
          Investor Relations Form
        </Text>
      </Heading>

      <Text color={"gray.600"} my={6} fontWeight={"bold"}>
        Investments Prospectus Document
      </Text>

      <Link
        color={"highlight.primary"}
        href="https://empathicai.s3.eu-central-1.amazonaws.com/forms/Prospectus_Sample.pdf"
        target="_blank"
        display="inline-flex"
        alignItems="center"
      >
        Investments Prospectus
        <RiExternalLinkLine style={{ marginLeft: "4px" }} />
      </Link>

      <Text mt={6} mb={2} color={"gray.600"} fontWeight={"bold"}>
        Form
      </Text>

      <Text color={"gray.600"} fontStyle={"italic"} fontSize={"14px"} mb={6}>
        Download the form below, fill it out, sign it, and then proceed to{" "}
        <b
          style={{ cursor: "pointer" }}
          onClick={() => setCurrentView("review")}
        >
          Review & Submit
        </b>
      </Text>

      <Flex direction={"column"} bg={"background"} w={"100%"} h={"fit-content"}>
        {!isLoading && (
          <Flex mb={4} align={"center"} justify={"space-between"}>
            {downloaded && (
              <Text
                color={"green.500"}
                display="inline-flex"
                alignItems="center"
                fontWeight={500}
              >
                <CiCircleCheck style={{ marginRight: "4px" }} /> Downloaded
              </Text>
            )}
            <Button
              colorScheme="blue"
              mt={2}
              py={2}
              px={{ base: 2, sm: 3 }}
              w={"fit-content"}
              h={"fit-content"}
              minH={"34px"}
              fontWeight={"500"}
              leftIcon={<FiDownload />}
              fontSize={{ base: "12px", md: "13px", lg: "16px" }}
              color={{ base: "gray.700", lg: "whiteAlpha.900" }}
              bg={"highlight.primary"}
              borderRadius={"18px"}
              letterSpacing={"0.03rem"}
              _hover={{ opacity: 0.8 }}
              _focus={{ opacity: 0.8 }}
              _focusWithin={{ opacity: 1 }}
              _active={{ opacity: 1 }}
              isLoading={isDownloading}
              transition={"all .3s ease"}
              loadingText="Downloading"
              onClick={() => downloadForm()}
            >
              {actionText}
            </Button>
          </Flex>
        )}
        <PdfPreview
          fileUrl={formURL}
          previewHeight={600}
          customLoading={<Spinner />}
          setLoading={setIsLoading}
        />
      </Flex>
    </Box>
  );
}
