import { axiosClient } from "api/axios";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentAuthData,
  setAccessToken,
  setUser,
} from "store/authSlice";

const useRefreshToken = () => {
  const dispatch = useDispatch();
  const { refreshToken } = useSelector(selectCurrentAuthData);
  const _userSub = localStorage.getItem("userSub");
  const userSub = _userSub ? JSON.parse(_userSub) : null;

  const refresh = async () => {
    try {
      const response = await axiosClient.post(
        "/api/v1/refresh",
        { refreshToken, userSub },
        {
          withCredentials: true,
        }
      );
      const { accessToken, user } = response?.data?.data;

      dispatch(setAccessToken(accessToken));
      dispatch(setUser(user));
      return { accessToken };
    } catch (error) {
      console.log(error);
      // dispatch(clearCredentials())
      return null;
    }
  };
  return refresh;
};

export default useRefreshToken;
