import { Flex, Image, Text } from "@chakra-ui/react";

interface CardProps {
  icon: string;
  heading: string;
  text: string;
}

function Card({ icon, heading, text }: CardProps) {
  return (
    <Flex gap={2}>
      {/* icon */}
      <Flex
        border={"1px solid rgba(23, 74, 228, 0.28)"}
        bg={"rgba(23, 74, 228, 0.08)"}
        borderRadius={"12px"}
        h={"fit-content"}
      >
        <Image
          src={icon}
          w={{ lg: "48px", xl: "42px", "2xl": "48px" }}
          h={{ lg: "48px", xl: "42px", "2xl": "48px" }}
          p={2}
        />
      </Flex>

      {/* heading & text */}
      <Flex
        direction={"column"}
        gap={1}
        bgGradient={
          "linear-gradient(90deg, rgba(23, 74, 228, 0.08) 0%, rgba(23, 74, 228, 0.02) 100%)"
        }
        p={3}
        borderRadius={"12px"}
        w={{ lg: "100%", "2xl": "480px" }}
      >
        <Text
          fontFamily={"Poppins, sans-serif"}
          fontWeight={"600"}
          color={"#2088be"}
          w={"fit-content"}
          fontSize={{ lg: "14px", "2xl": "16px" }}
        >
          {heading}
        </Text>
        <Text color={"gray.700"} fontSize={{ lg: "14px", "2xl": "16px" }}>
          {text}
        </Text>
      </Flex>
    </Flex>
  );
}

export default Card;
