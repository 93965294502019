import { Flex, Icon, Image, Text } from "@chakra-ui/react";

import { BiSolidQuoteAltLeft } from "react-icons/bi";
import roundedShape from "assets/home/ourMission/rounded-shape.svg";
import happyFamilyImg from "assets/home/ourMission/happy-family.png";

function QuotedPicture() {
  return (
    <Flex
      display={{ lg: "none", xl: "flex" }}
      w={"40%"}
      h={"100%"}
      align={"center"}
      justify={"center"}
    >
      <Flex
        p={{ xl: "10px", "2xl": "30px" }}
        position={"relative"}
        align={"center"}
        justify={"center"}
      >
        <Image
          position={"absolute"}
          h={"100%"}
          bottom={"-60px"}
          left={"-20px"}
          objectFit={"contain"}
          src={roundedShape}
        />
        <Flex h={"100%"} w={"100%"} direction={"column"} gap={4}>
          <Image
            w={{ xl: "305px", "2xl": "400px" }}
            h={"auto"}
            src={happyFamilyImg}
            borderRadius={{ xl: "20px", "2xl": "30px" }}
            zIndex={2}
          />
          <Flex
            gap={2}
            justify={"center"}
            display={{ xl: "none", "2xl": "flex" }}
          >
            <Icon
              as={BiSolidQuoteAltLeft}
              boxSize={"18px"}
              color={"#202053"}
              zIndex={2}
            />
            <Text
              fontWeight={"500"}
              color={"#202053"}
              zIndex={2}
              w={"fit-content"}
            >
              Only empathic AI can truly serve humanity
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default QuotedPicture;
