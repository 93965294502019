import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "store/authSlice";

const useAllowAccess = () => {
  const { user } = useSelector(selectCurrentAuthData);
  const userIsAnAllowedRoles = (types: string[]) => types.includes(user?.role);
  return { userIsAnAllowedRoles };
};

export default useAllowAccess;
