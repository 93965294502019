import { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import { axiosClient } from "api/axios";
import { useDispatch } from "react-redux";
import { setAccessToken, setRefreshToken, setUser } from "store/authSlice";

const LoginForm = () => {
  // States
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isPwdVisible, setIsPwdVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Hooks
  const toast = useToast();
  const dispatch = useDispatch();

  // Handlers
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  const onSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault();
    try {
      if (username === "" || password === "") return;

      const data = { username, password };

      setIsLoading(true);
      const response = await axiosClient.post(
        `/api/v1/auth`,
        JSON.stringify(data),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const { user, accessToken, refreshToken } = response?.data?.data;

      dispatch(setUser(user));
      dispatch(setAccessToken(accessToken));
      dispatch(setRefreshToken(refreshToken));
    } catch (error: any) {
      // No response from server
      console.log("error: ", error);
      if (!error.response) {
        toast({
          title: "Auth Error",
          description: "No server response!",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      if (error.response.data.message === "USER_DISABLED") {
        toast({
          title: "Access Revoked",
          description:
            "Access revoked for this user. Contact admin for account activation",
          status: "error",
          duration: 10000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Auth Error",
          description: error.response.data.message,
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box as="form" onSubmit={onSubmit} className="form column">
      <FormControl isRequired>
        <FormLabel>Email Address</FormLabel>
        <Input
          type="email"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          // isInvalid={!validateEmail(username)}
          onKeyDown={handleKeyDown}
          errorBorderColor="red.300"
          placeholder="Email Address"
          borderWidth={1}
          bg={"background"}
          borderColor={"gray.200"}
          _hover={{ borderColor: "gray.300" }}
          _focusVisible={{ borderColor: "blue.300" }}
        />
      </FormControl>
      <FormControl isRequired mt={4}>
        <FormLabel>Password</FormLabel>
        <InputGroup>
          <Input
            type={isPwdVisible ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Password"
            borderWidth={1}
            bg={"background"}
            borderColor={"gray.200"}
            _hover={{ borderColor: "gray.300" }}
            _focusVisible={{ borderColor: "blue.300" }}
          />
          <InputRightElement>
            <Button
              onClick={() => setIsPwdVisible(!isPwdVisible)}
              color={"secondary.300"}
              size="sm"
            >
              {isPwdVisible ? <ViewOffIcon /> : <ViewIcon />}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Button
        type="submit"
        colorScheme="blue"
        mt={6}
        isLoading={isLoading}
        loadingText={"Authenticating..."}
        width="full"
      >
        Login
      </Button>
    </Box>
  );
};

export default LoginForm;
