import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  IconButton,
  Input,
  Stack,
  StackDivider,
  Text,
  useToast,
} from "@chakra-ui/react";
import { MdOutlineEmail } from "react-icons/md";
import { CgOrganisation } from "react-icons/cg";
import { FiEdit3 } from "react-icons/fi";

import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuthData, setUser } from "store/authSlice";
import { useState } from "react";
import { useUsersAPI } from "api/useUsersAPI";

export interface DataProps {
  data: any;
  isLoading: boolean;
  setCurrentView: (value: string) => void;
}

interface updateProfileProps {
  id: string;
  firstname?: string;
  lastname?: string;
}

export default function Profile({
  data,
  isLoading,
  setCurrentView,
}: DataProps) {
  const { user } = useSelector(selectCurrentAuthData);

  //States
  const [editFirstname, setEditFirstname] = useState(false);
  const [editLastname, setEditLastname] = useState(false);
  const [firstname, setFirstname] = useState(user?.firstname || "");
  const [lastname, setLastname] = useState(user?.lastname || "");
  const [updatingFirst, setUpdatingFirst] = useState(false);
  const [updatingLast, setUpdatingLast] = useState(false);

  // Hooks
  const toast = useToast();
  const dispatch = useDispatch();

  // APIs
  const { updateUser } = useUsersAPI();

  const updateFirstname = async () => {
    if (firstname.trim() === "") return;
    setUpdatingFirst(true);
    await updateProfile("first");
    setEditFirstname(false);
  };

  const updateLastname = async () => {
    if (lastname.trim() === "") return;
    setUpdatingLast(true);
    await updateProfile("last");
    setEditLastname(false);
  };

  const updateProfile = async (name: string) => {
    let payload: updateProfileProps = { id: user?.id };
    if (name === "first") payload.firstname = firstname.trim();
    if (name === "last") payload.lastname = lastname.trim();

    try {
      await updateUser(payload);
      toast({
        description: "Profile updated successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      const newData = { ...user };
      if (name === "first") newData.firstname = firstname.trim();
      if (name === "last") newData.lastname = lastname.trim();

      dispatch(setUser(newData));
      setUpdatingFirst(false);
      setUpdatingLast(false);
    } catch (error: any) {
      console.log(error);
      toast({
        description: error.response.data.message
          ? error.response.data.message
          : "Profile update failed. Try again",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setUpdatingFirst(false);
      setUpdatingLast(false);
    } finally {
      setUpdatingFirst(false);
      setUpdatingLast(false);
    }
  };

  return (
    <Box w={"100%"}>
      <Heading
        as={"h2"}
        fontFamily="Poppins, sans-serif"
        lineHeight="1.3"
        fontSize={{ lg: "22px", xl: "26px", "2xl": "34px" }}
        w={"100%"}
        display={"flex"}
        mb={6}
      >
        <Text
          bgGradient="linear(to right, rgb(31.51, 32.14, 82.55) 0%, rgb(32, 136, 190) 100%)"
          bgClip="text"
          color="transparent"
        >
          Profile
        </Text>
      </Heading>
      <Text color={"gray.600"} fontStyle={"italic"} fontSize={"14px"} mb={6}>
        Update your your profile then proceed to the{" "}
        <b
          style={{ cursor: "pointer" }}
          onClick={() => setCurrentView("relations")}
        >
          next step
        </b>
      </Text>
      {data?.org === null && (
        <Text
          w={"100%"}
          fontSize={"sm"}
          color={"red.500"}
          borderRadius={"6px"}
          bg={"rgba(255, 0, 0, .02)"}
          borderStyle={"dashed"}
          borderWidth={1}
          borderColor={"rgba(255, 0, 0, .35)"}
          p={3}
          mt={2}
        >
          <b>Invalid Access Code</b> <br />
          Your access code is invalid. Please contact admin for assistance
        </Text>
      )}
      <Text color={"gray.600"} my={6}>
        Personal Details
      </Text>
      <Card variant={"outline"}>
        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box>
              <Flex direction={"row"} align={"center"}>
                <Flex mr={2} color={"gray.500"}>
                  <MdOutlineEmail fontSize={"26px"} />
                </Flex>
                <Flex direction={"column"}>
                  <Heading size="xs" color={"gray.600"}>
                    Email
                  </Heading>
                  <Text pt="0" fontSize="sm" color={"gray.500"}>
                    {user?.email}
                  </Text>
                </Flex>
              </Flex>
            </Box>
            <Box>
              {editFirstname && (
                <Flex direction={"column"} w={"100%"}>
                  <Input
                    type="text"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    placeholder="Firstname"
                    borderRadius={"6px"}
                    bg={"background"}
                    borderWidth={1}
                    borderColor={"gray.200"}
                    _hover={{ borderColor: "gray.300" }}
                    _focusVisible={{ borderColor: "blue.300" }}
                  />

                  <Flex direction={"row"} mt={4}>
                    <Button
                      py={2}
                      px={{ base: 2, sm: 3 }}
                      w={"fit-content"}
                      h={"fit-content"}
                      minH={"34px"}
                      fontWeight={"500"}
                      fontSize={{ base: "12px", md: "13px", lg: "16px" }}
                      color={{ base: "gray.700", lg: "whiteAlpha.900" }}
                      bg={"highlight.primary"}
                      letterSpacing={"0.03rem"}
                      _hover={{ opacity: 0.8 }}
                      _focus={{ opacity: 0.8 }}
                      _focusWithin={{ opacity: 1 }}
                      _active={{ opacity: 1 }}
                      isLoading={updatingFirst}
                      transition={"all .3s ease"}
                      loadingText={"Updating"}
                      onClick={() => updateFirstname()}
                    >
                      Save
                    </Button>

                    <Button
                      ml={4}
                      py={2}
                      minH={"34px"}
                      w={"fit-content"}
                      h={"fit-content"}
                      fontWeight={"500"}
                      px={{ base: 2, sm: 3 }}
                      fontSize={{ base: "12px", md: "13px", lg: "16px" }}
                      borderWidth={1}
                      color={"red.500"}
                      bg={"transparent"}
                      // borderRadius={"30px"}
                      borderColor={"transparent"}
                      letterSpacing={".02rem"}
                      _active={{ borderColor: "red.500" }}
                      _hover={{ borderColor: "red.500" }}
                      _focus={{ borderColor: "red.500" }}
                      _focusWithin={{ borderColor: "red.500" }}
                      transition={"all .3s ease"}
                      onClick={() => setEditFirstname(false)}
                    >
                      Cancel
                    </Button>
                  </Flex>
                </Flex>
              )}

              {!editFirstname && (
                <Flex direction={"column"}>
                  <Flex justify="space-between">
                    <Flex direction={"column"}>
                      <Heading size="xs" color={"gray.800"}>
                        First Name
                      </Heading>
                      {user.firstname ? (
                        <Text pt="1" fontSize="sm" color={"gray.900"}>
                          {user.firstname}
                        </Text>
                      ) : (
                        <Text pt="1" fontSize="sm" color={"red.300"}>
                          Update firstname
                        </Text>
                      )}
                    </Flex>

                    <Flex>
                      <IconButton
                        size="md"
                        fontSize="lg"
                        variant="ghost"
                        icon={<FiEdit3 />}
                        color={"highlight.primary"}
                        aria-label={"Update firstname"}
                        onClick={() => setEditFirstname(true)}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </Box>
            <Box>
              {editLastname && (
                <Flex direction={"column"} w={"100%"}>
                  <Input
                    type="text"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                    placeholder="Lastname"
                    borderRadius={"6px"}
                    bg={"background"}
                    borderWidth={1}
                    borderColor={"gray.200"}
                    _hover={{ borderColor: "gray.300" }}
                    _focusVisible={{ borderColor: "blue.300" }}
                  />

                  <Flex direction={"row"} mt={4}>
                    <Button
                      py={2}
                      px={{ base: 2, sm: 3 }}
                      w={"fit-content"}
                      h={"fit-content"}
                      minH={"34px"}
                      fontWeight={"500"}
                      fontSize={{ base: "12px", md: "13px", lg: "16px" }}
                      color={{ base: "gray.700", lg: "whiteAlpha.900" }}
                      bg={"highlight.primary"}
                      letterSpacing={"0.03rem"}
                      _hover={{ opacity: 0.8 }}
                      _focus={{ opacity: 0.8 }}
                      _focusWithin={{ opacity: 1 }}
                      _active={{ opacity: 1 }}
                      isLoading={updatingLast}
                      transition={"all .3s ease"}
                      loadingText={"Updating"}
                      onClick={() => updateLastname()}
                    >
                      Save
                    </Button>

                    <Button
                      ml={4}
                      py={2}
                      minH={"34px"}
                      w={"fit-content"}
                      h={"fit-content"}
                      fontWeight={"500"}
                      px={{ base: 2, sm: 3 }}
                      fontSize={{ base: "12px", md: "13px", lg: "16px" }}
                      borderWidth={1}
                      color={"red.500"}
                      bg={"transparent"}
                      borderColor={"transparent"}
                      letterSpacing={".02rem"}
                      _active={{ borderColor: "red.500" }}
                      _hover={{ borderColor: "red.500" }}
                      _focus={{ borderColor: "red.500" }}
                      _focusWithin={{ borderColor: "red.500" }}
                      transition={"all .3s ease"}
                      onClick={() => setEditLastname(false)}
                    >
                      Cancel
                    </Button>
                  </Flex>
                </Flex>
              )}

              {!editLastname && (
                <Flex direction={"column"}>
                  <Flex justify="space-between">
                    <Flex direction={"column"}>
                      <Heading size="xs" color={"gray.800"}>
                        Last Name
                      </Heading>
                      {user.lastname ? (
                        <Text pt="1" fontSize="sm" color={"gray.900"}>
                          {user.lastname}
                        </Text>
                      ) : (
                        <Text pt="1" fontSize="sm" color={"red.300"}>
                          Update lastname
                        </Text>
                      )}
                    </Flex>

                    <Flex>
                      <IconButton
                        size="md"
                        fontSize="lg"
                        variant="ghost"
                        icon={<FiEdit3 />}
                        color={"highlight.primary"}
                        aria-label={"Update lastname"}
                        onClick={() => setEditLastname(true)}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </Box>
          </Stack>
        </CardBody>
      </Card>

      {data.org && (
        <>
          <Text color={"gray.600"} my={6}>
            Organization
          </Text>
          <Card variant={"outline"}>
            <CardBody>
              <Stack divider={<StackDivider />} spacing="4">
                <Box>
                  <Flex direction={"row"} align={"center"}>
                    <Flex mr={2} color={"gray.500"}>
                      <CgOrganisation fontSize={"26px"} />
                    </Flex>
                    <Flex direction={"column"}>
                      <Heading size="xs" color={"gray.600"}>
                        Name
                      </Heading>
                      <Text pt="0" fontSize="sm" color={"gray.500"}>
                        {data?.org?.name}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
                <Box>
                  <Flex direction={"row"} align={"center"}>
                    <Flex mr={2} color={"gray.500"}>
                      <MdOutlineEmail fontSize={"26px"} />
                    </Flex>
                    <Flex direction={"column"}>
                      <Heading size="xs" color={"gray.600"}>
                        Email
                      </Heading>
                      <Text pt="0" fontSize="sm" color={"gray.500"}>
                        {data?.org?.email}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
                <Box>
                  <Heading size="xs" color={"gray.600"}>
                    Access Code
                  </Heading>
                  <Text pt="1" fontSize="sm" color={"gray.700"}>
                    {data?.org?.access_code}
                  </Text>
                </Box>
              </Stack>
            </CardBody>
          </Card>
        </>
      )}
    </Box>
  );
}
