import { useCallback, useEffect, useState } from "react";
import {
  Flex,
  useToast,
  Heading,
  Box,
  VStack,
  Stack,
  StackDivider,
} from "@chakra-ui/react";
import BackToTop from "components/ui/BackToTop";

import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "store/authSlice";
import { useUsersAPI } from "api/useUsersAPI";

import Profile from "components/investor/Profile";
import Relations from "components/investor/Relations";
import Review from "components/investor/Review";

import Spinner from "components/ui/SpinnerDots";

export default function InvestorPage() {
  const { user } = useSelector(selectCurrentAuthData);

  // States
  const [isLoading, setIsLoading] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  const [currentView, setCurrentView] = useState("profile");
  const [codeInfo, setCodeInfo] = useState<any>();

  // Hooks / APIs
  const toast = useToast();
  const { getReferral } = useUsersAPI();

  const fetchReferral = useCallback(async () => {
    try {
      const res = await getReferral(user.id);
      setCodeInfo(res?.data);
    } catch (error: any) {
      toast({
        description:
          error?.response?.data?.message ||
          "Something went wrong while fetching referral",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error(error);
      setDataFetched(true);
    } finally {
      setIsLoading(false);
      setDataFetched(true);
    }
  }, [getReferral, toast, user.id]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (!dataFetched) {
      fetchReferral();
    }
  }, [dataFetched, fetchReferral]);

  const switchView = (view: string) => {
    setCurrentView(view);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const showToast = () => {
    toast({
      title: "Invalid Access Code",
      description:
        "Your access code is invalid. Please contact admin for assistance",
      status: "warning",
      duration: 10000,
      isClosable: true,
    });
  };

  return (
    <Flex direction={"column"} bg={"transparent"} mb={"112px"}>
      <BackToTop />

      <Box
        maxW="1100px"
        margin="auto"
        pt="140px"
        pb="112px"
        px={4}
        w={"85%"}
        mx={"auto"}
      >
        <VStack spacing={6} w={"100%"}>
          <Stack
            direction={["column", null, "row"]}
            w={"100%"}
            spacing={4}
            mb={currentView === "relations" ? "112px" : ""}
          >
            <Flex
              w={["100%", null, "30%"]}
              mt={[0, null, "48px"]}
              h={"100%"}
              textAlign={"left"}
              direction={"column"}
              position="sticky"
              top="140px"
            >
              <Stack divider={<StackDivider />} spacing="0">
                <Box
                  p={3}
                  cursor={"pointer"}
                  bg={currentView === "profile" ? "#ffffff" : ""}
                  _hover={{ bg: "#ffffff" }}
                  // _hover={{ bg: "#e2e2e2" }}
                  onClick={() => switchView("profile")}
                >
                  <Heading size="xs" textTransform="uppercase">
                    Overview
                  </Heading>
                </Box>
                <Box
                  p={3}
                  cursor={"pointer"}
                  bg={currentView === "relations" ? "#ffffff" : ""}
                  _hover={{ bg: "#ffffff" }}
                  onClick={() =>
                    codeInfo?.org === null
                      ? showToast()
                      : switchView("relations")
                  }
                >
                  <Heading size="xs" textTransform="uppercase">
                    Investor Relations
                  </Heading>
                </Box>
                <Box
                  p={3}
                  cursor={"pointer"}
                  bg={currentView === "review" ? "#ffffff" : ""}
                  _hover={{ bg: "#ffffff" }}
                  onClick={() =>
                    codeInfo?.org === null ? showToast() : switchView("review")
                  }
                >
                  <Heading size="xs" textTransform="uppercase">
                    Review & Submit
                  </Heading>
                </Box>
              </Stack>
            </Flex>
            <Flex
              w={["100%", null, "70%"]}
              h={["fit-content", null, "600px"]}
              textAlign={"left"}
              direction={"column"}
              ml={[0, null, "40px"]}
            >
              {isLoading ? (
                <Box mt={12}>
                  <Spinner />
                </Box>
              ) : (
                <>
                  {currentView === "profile" && (
                    <Profile
                      data={codeInfo}
                      isLoading={isLoading}
                      setCurrentView={setCurrentView}
                    />
                  )}
                  {currentView === "relations" && (
                    <Relations setCurrentView={setCurrentView} />
                  )}
                  {currentView === "review" && <Review />}
                </>
              )}
            </Flex>
          </Stack>
        </VStack>
      </Box>
    </Flex>
  );
}
