import { ReactNode, ChangeEvent, DragEvent } from "react";
import {
  InputGroup,
  Button,
  useTheme,
  Input,
  FormControl,
  Text,
  Box,
} from "@chakra-ui/react";
import { FiUpload } from "react-icons/fi"; // Import your icon library as needed

type FileUploaderProps = {
  dragActive?: boolean;
  valid?: boolean;
  accept?: string;
  children?: ReactNode; // Ensure children prop is correctly typed
  handleFileChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onButtonClick?: () => void;
  error?: string;
  handleDrag?: (e: DragEvent) => void;
  handleDrop?: (e: DragEvent) => void;
};

const FileUploader = ({
  dragActive,
  valid,
  accept,
  children,
  handleFileChange,
  onButtonClick,
  handleDrop,
  error,
  handleDrag,
}: FileUploaderProps) => {
  // Theme
  const { colors } = useTheme();

  return (
    <Box
      as={"form"}
      id="form-file-upload"
      backgroundColor={"white"}
      p="40px"
      justifyContent="center"
      textAlign="center"
      w="100%"
      my="14px"
      borderRadius="10px"
      borderStyle="dotted"
      borderColor={colors.gray[300]}
      borderWidth={2}
      position={dragActive ? "absolute" : "relative"}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      <Text fontSize="16px" fontWeight="500" color={colors.gray[500]}>
        Drop the signed form here
      </Text>
      <Text fontSize="16px" color="highlight.primary">
        or
      </Text>
      <FormControl
        isInvalid={valid}
        isRequired
        id="uploadFile"
        w="100%"
        mt="14px"
        textAlign={"center"}
      >
        <InputGroup
          id="file-upload"
          justifyContent={"center"}
          width={"fit-content"}
          justifyItems={"center"}
          margin={"0 auto"}
        >
          <Input
            type="file"
            name="file-uploader-field"
            id="file-uploader-field"
            multiple={false}
            accept={accept}
            hidden={false}
            onChange={handleFileChange}
            style={{
              width: "100%",
              height: "50px",
              opacity: 0,
              position: "absolute",
              top: 0,
              zIndex: 1,
              cursor: "pointer",
            }}
          />
          <Button
            zIndex={0}
            backgroundColor="highlight.primary"
            onClick={onButtonClick}
            leftIcon={<FiUpload color={colors.white} />}
          >
            {children}
          </Button>
        </InputGroup>
        <Text
          textAlign={"center"}
          alignSelf={"center"}
          color={"red.500"}
          mt={"20px"}
          fontSize={"14px"}
        >
          {error}
        </Text>
        {!error && (
          <Text
            textAlign={"center"}
            alignSelf={"center"}
            color={"gray.400"}
            mt={"20px"}
            fontSize={"14px"}
          >
            Upload the filled and signed copy of the investor relations form
          </Text>
        )}
      </FormControl>
    </Box>
  );
};

export default FileUploader;
