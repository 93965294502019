import { AnimationControls, motion } from "framer-motion";

import empathicImg from "assets/home/brands/solid/empathic.svg";
import empathicOutlinedImg from "assets/home/brands/outline/empathicOutlined.svg";

import vitafluenceImg from "assets/home/brands/solid/vitafluence.svg";
import vitafluenceOutlinedImg from "assets/home/brands/outline/vitafluenceOutlined.svg";

import stealthImg from "assets/home/brands/solid/stealth.svg";
import stealthOutlinedImg from "assets/home/brands/outline/stealthOutlined.svg";

import lakesAIImg from "assets/home/brands/solid/lakesAI.svg";
import lakesAIOutlinedImg from "assets/home/brands/outline/lakesAIOutlined.svg";

import moleculeLakeImg from "assets/home/brands/solid/moleculeLake.svg";
import moleculeLakeOutlinedImg from "assets/home/brands/outline/moleculeLakeOutlined.svg";

import iZolaImg from "assets/home/brands/solid/izola.svg";
import iZolaOutlinedImg from "assets/home/brands/outline/izolaOutlined.svg";

import xaidiImg from "assets/home/brands/solid/xaidi.svg";
import xaidiOutlinedImg from "assets/home/brands/outline/xaidiOutlined.svg";

import neurobeeImg from "assets/home/brands/solid/neurobee.svg";
import neurobeeOutlineImg from "assets/home/brands/outline/neurobeeOutline.svg";

interface Section {
  name: string;
  heading: string;
  text: string;
  url?: string;
  startColor?: string;
  stopColor?: string;
}

interface Node {
  name: string;
  src: string;
  altSrc: string;
}

interface Props {
  animate: AnimationControls;
}

export const nodes: Node[] = [
  {
    name: "Vitafluence",
    src: vitafluenceImg,
    altSrc: vitafluenceOutlinedImg,
  },
  {
    name: "Stealth",
    src: stealthImg,
    altSrc: stealthOutlinedImg,
  },
  {
    name: "Stealth",
    src: stealthImg,
    altSrc: stealthOutlinedImg,
  },
  {
    name: "iZola",
    src: iZolaImg,
    altSrc: iZolaOutlinedImg,
  },
  {
    name: "LakesAI",
    src: lakesAIImg,
    altSrc: lakesAIOutlinedImg,
  },
  {
    name: "EmpathicAI",
    src: empathicImg,
    altSrc: empathicOutlinedImg,
  },
  {
    name: "Xaidi",
    src: xaidiImg,
    altSrc: xaidiOutlinedImg,
  },
  {
    name: "MoleculeLake",
    src: moleculeLakeImg,
    altSrc: moleculeLakeOutlinedImg,
  },
  {
    name: "Stealth",
    src: stealthImg,
    altSrc: stealthOutlinedImg,
  },
  {
    name: "Stealth",
    src: stealthImg,
    altSrc: stealthOutlinedImg,
  },
  {
    name: "NeuroBee",
    src: neurobeeImg,
    altSrc: neurobeeOutlineImg,
  },
];

export const sections: Section[] = [
  {
    name: "EmpathicAI",
    heading: "Ethical AI venture builder pioneering health solutions",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus, aperiam, ipsa dignissimos fugit velit cum facilis necessitatibus natus assumenda debitis oluptatem maiores              repudiandae omnis! Quod atque assumenda expedita libero molestias?",
    url: "",
  },
  {
    name: "Vitafluence",
    heading: "Duis aute irure dolor in reprehe",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus, aperiam, ipsa dignissimos fugit velit cum facilis necessitatibus natus assumenda debitis oluptatem maiores              repudiandae omnis! Quod atque assumenda expedita libero molestias?",
    url: "",
    startColor: "#313283",
    stopColor: "#2198D0",
  },
  {
    name: "LakesAI",
    heading: "Excepteur sint occaecat cupidatat",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus, aperiam, ipsa dignissimos fugit velit cum facilis necessitatibus natus assumenda debitis oluptatem maiores              repudiandae omnis! Quod atque assumenda expedita libero molestias?",
    url: "",
    startColor: "#00bfb2",
    stopColor: "#ff2c55",
  },
  {
    name: "MoleculeLake",
    heading: "Reprehenderit in voluptate velit esse cillum",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus, aperiam, ipsa dignissimos fugit velit cum facilis necessitatibus natus assumenda debitis oluptatem maiores              repudiandae omnis! Quod atque assumenda expedita libero molestias?",
    url: "",
    startColor: "#0000ff",
    stopColor: "#00c1b4",
  },
  {
    name: "iZola",
    heading:
      "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus, aperiam, ipsa dignissimos fugit velit cum facilis necessitatibus natus assumenda debitis oluptatem maiores              repudiandae omnis! Quod atque assumenda expedita libero molestias?",
    url: "",
    startColor: "#6b61e8",
    stopColor: "#f2da5e",
  },
  {
    name: "Xaidi",
    heading: "Laboris nisi ut aliquip ex ea commodo consequat",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus, aperiam, ipsa dignissimos fugit velit cum facilis necessitatibus natus assumenda debitis oluptatem maiores              repudiandae omnis! Quod atque assumenda expedita libero molestias?",
    url: "",
    startColor: "#5d5bd6",
    stopColor: "#aba7f8",
  },
  {
    name: "NeuroBee",
    heading: "Culpa qui officia deserunt mollit anim id est laborum",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus, aperiam, ipsa dignissimos fugit velit cum facilis necessitatibus natus assumenda debitis oluptatem maiores              repudiandae omnis! Quod atque assumenda expedita libero molestias?",
    url: "",
    startColor: "#f5d804",
    stopColor: "#000000",
  },
];

// NOTE: connections details
// Empathic     -> all items
// Stealth      -> Empathic
// MoleculeLake ->  Empathic, Vitafluence, Lakesai
// Lakesai      -> Empathic, Vitafluece
// Vitafluence  -> Empathic
// Neurobee     -> Empathic, Vitafluence
// iZola        -> Empathic, Vitafluence
// Xaidi        -> Empathic, iZola and Vitafluence

export function getNodeLinks(text: string) {
  switch (text.toLowerCase()) {
    case "vitafluence":
      return [Line1];
    case "lakesai":
      return [Line2, Line1];
    case "moleculelake":
      return [Line3, Line1, Line2];
    case "izola":
      return [Line3Reversed, Line1];
    case "xaidi":
      return [Line2Reversed, Line3Reversed, Line1];
    case "neurobee":
      return [Line1Reversed, Line1];
    case "stealth1":
      return [Line4];
    case "stealth2":
      return [Line5];
    case "stealth3":
      return [Line5Reversed];
    case "stealth4":
      return [Line4Reversed];
    default:
      return null;
  }
}

// TODO: empathicAI links
export function getNodeLinksProps(text: string) {
  switch (text.toLowerCase()) {
    case "vitafluence":
      return [{ w: "40px", h: "172px", top: "50%", left: "100%" }];
    case "lakesai":
      return [
        { w: "40px", h: "2px", top: "50%", left: "100%" },
        { w: "40px", h: "172px", top: "-125.5px", left: "100%" },
      ];
    case "moleculelake":
      return [
        { w: "40px", h: "172px", top: "calc(-50% - 81px)", left: "100%" },
        { w: "40px", h: "172px", top: "-295.5px", left: "100%" },
        { w: "40px", h: "2px", top: "-125.5px", left: "100%" },
      ];
    case "xaidi":
      return [
        { w: "40px", h: "2px", top: "50%", left: "-41px" },
        { w: "40px", h: "172px", top: "-125.5px", left: "-41px" },
        { w: "40px", h: "172px", top: "-125.5px", left: "-301px" },
      ];
    case "izola":
      return [
        { w: "40px", h: "172px", top: "50%", left: "-41px" },
        { w: "40px", h: "172px", top: "50%", left: "-301px" },
      ];
    case "neurobee":
      return [
        { w: "40px", h: "172px", top: "calc(-50% - 81px)", left: "-41px" },
        { w: "40px", h: "172px", top: "-295.5px", left: "-301px" },
      ];
    case "stealth1":
      return [{ w: "65px", h: "80px", top: "100%", left: "50%" }];
    case "stealth2":
      return [{ w: "65px", h: "80px", top: "100%", left: "-22px" }];
    case "stealth3":
      return [{ w: "65px", h: "80px", top: "-80px", left: "50%" }];
    case "stealth4":
      return [{ w: "65px", h: "80px", top: "-80px", left: "-22px" }];
    default:
      return [];
  }
}

const startColor = "#8250FD";
const stopColor = "#00C1B4";

function Line1({ animate }: Props) {
  return (
    <svg viewBox={"0 0 40 170"}>
      <defs>
        <motion.linearGradient
          id={"line1Grad"}
          x1={"0%"}
          y1={"0%"}
          x2={"100%"}
          y2={"100%"}
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
        >
          <stop
            offset={"0%"}
            style={{ stopColor: startColor, stopOpacity: 1 }}
          />
          <stop
            offset={"100%"}
            style={{ stopColor: stopColor, stopOpacity: 1 }}
          />
        </motion.linearGradient>
      </defs>

      <motion.path
        d={"m0,0h15c2.76,0,5,2.25,5,5.03v160c0,2.75,2.21,4.97,4.94,4.97h15.06"}
        fill={"none"}
        strokeWidth={"2px"}
        stroke="url(#line1Grad)"
        strokeMiterlimit={10}
        initial={{ pathLength: 0 }}
        animate={animate}
        transition={{ duration: 1, ease: "easeIn" }}
      />
    </svg>
  );
}

function Line2({ animate }: Props) {
  return (
    <svg viewBox={"0 0 40 2"}>
      <defs>
        <linearGradient
          id={"line2Grad"}
          gradientUnits={"userSpaceOnUse"}
          x1={"0%"}
          x2={"100%"}
          y1={"0%"}
          y2={"100%"}
        >
          <motion.stop
            offset={"0%"}
            style={{ stopColor: startColor, stopOpacity: 1 }}
            animate={{ stopColor: stopColor }}
            transition={{ duration: 1 }}
          />
          <motion.stop
            offset={"100%"}
            style={{ stopColor: stopColor, stopOpacity: 1 }}
            animate={{ stopColor: startColor }}
            transition={{ duration: 1 }}
          />
        </linearGradient>
      </defs>

      <motion.path
        d="M0 1 H40"
        fill={"none"}
        strokeWidth={"2px"}
        stroke={"url(#line2Grad)"}
        strokeMiterlimit={10}
        initial={{ pathLength: 0 }}
        animate={animate}
        transition={{ duration: 1, ease: "easeIn" }}
      />
    </svg>
  );
}

function Line3({ animate }: Props) {
  return (
    <svg viewBox={"0 0 40 170"}>
      <defs>
        <motion.linearGradient
          id={"line3Grad"}
          x1={"0%"}
          y1={"0%"}
          x2={"100%"}
          y2={"100%"}
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
        >
          <stop
            offset={"0%"}
            style={{ stopColor: stopColor, stopOpacity: 1 }}
          />
          <stop
            offset={"100%"}
            style={{ stopColor: startColor, stopOpacity: 1 }}
          />
        </motion.linearGradient>
      </defs>

      <motion.path
        d={"m0,170h15c2.76,0,5-2.24,5-5V5c0-2.76,2.24-5,5-5h15"}
        fill={"none"}
        strokeWidth={"2px"}
        stroke={"url(#line3Grad)"}
        strokeMiterlimit={10}
        initial={{ pathLength: 0 }}
        animate={animate}
        transition={{ duration: 1, ease: "easeIn" }}
      />
    </svg>
  );
}

function Line4({ animate }: Props) {
  return (
    <svg viewBox="0 0 65 80">
      <defs>
        <motion.linearGradient
          id={"line4Grad"}
          x1={"0%"}
          y1={"0%"}
          x2={"100%"}
          y2={"100%"}
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
        >
          <stop
            offset={"0%"}
            style={{ stopColor: startColor, stopOpacity: 1 }}
          />
          <stop
            offset={"100%"}
            style={{ stopColor: stopColor, stopOpacity: 1 }}
          />
        </motion.linearGradient>
      </defs>

      <motion.path
        d={
          "m1,.5v34.56c0,2.73,2.17,4.94,4.85,4.94h53.31c2.68,0,4.85,2.21,4.85,4.94v34.56"
        }
        fill={"none"}
        stroke={"url(#line4Grad)"}
        strokeWidth={"2px"}
        strokeMiterlimit={10}
        initial={{ pathLength: 0 }}
        animate={animate}
        transition={{ duration: 1, ease: "easeIn" }}
      />
    </svg>
  );
}

function Line5({ animate }: Props) {
  return (
    <svg viewBox="0 0 65 80">
      <defs>
        <motion.linearGradient
          id={"line5Grad"}
          x1={"0%"}
          y1={"0%"}
          x2={"100%"}
          y2={"100%"}
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
        >
          <stop
            offset={"0%"}
            style={{ stopColor: stopColor, stopOpacity: 1 }}
          />
          <stop
            offset={"100%"}
            style={{ stopColor: startColor, stopOpacity: 1 }}
          />
        </motion.linearGradient>
      </defs>

      <motion.path
        d={
          "m64,.5v34.56c0,2.73-2.17,4.94-4.85,4.94H5.85c-2.68,0-4.85,2.21-4.85,4.94v34.56"
        }
        fill={"none"}
        stroke={"url(#line5Grad)"}
        strokeWidth={"2px"}
        strokeMiterlimit={10}
        initial={{ pathLength: 0 }}
        animate={animate}
        transition={{ duration: 1, ease: "easeIn" }}
      />
    </svg>
  );
}

function Line1Reversed({ animate }: Props) {
  return (
    <svg viewBox="0 0 40 170">
      <defs>
        <motion.linearGradient
          id={"line1ReversedGrad"}
          x1={"0%"}
          y1={"0%"}
          x2={"100%"}
          y2={"100%"}
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
        >
          <stop
            offset={"0%"}
            style={{ stopColor: stopColor, stopOpacity: 1 }}
          />
          <stop
            offset={"100%"}
            style={{ stopColor: startColor, stopOpacity: 1 }}
          />
        </motion.linearGradient>
      </defs>

      <motion.path
        d={"m40,170h-15c-2.76,0-5-2.24-5-5V5c0-2.76-2.24-5-5-5H0"}
        fill={"none"}
        strokeWidth={"2px"}
        stroke={"url(#line1ReversedGrad)"}
        strokeMiterlimit={10}
        initial={{ pathLength: 0 }}
        animate={animate}
        transition={{ duration: 1, ease: "easeIn" }}
      />
    </svg>
  );
}

function Line2Reversed({ animate }: Props) {
  return (
    <svg viewBox="0 0 40 2">
      <defs>
        <linearGradient
          id={"line2ReversedGrad"}
          gradientUnits={"userSpaceOnUse"}
          x1={"0%"}
          x2={"100%"}
          y1={"0%"}
          y2={"100%"}
        >
          <motion.stop
            offset={"0%"}
            style={{ stopColor: stopColor, stopOpacity: 1 }}
            animate={{ stopColor: startColor }}
            transition={{ duration: 1 }}
          />
          <motion.stop
            offset={"100%"}
            style={{ stopColor: startColor, stopOpacity: 1 }}
            animate={{ stopColor: stopColor }}
            transition={{ duration: 1 }}
          />
        </linearGradient>
      </defs>

      <motion.path
        d="M40,1 H0"
        fill={"none"}
        strokeWidth={"2px"}
        stroke={"url(#line2ReversedGrad)"}
        strokeMiterlimit={10}
        initial={{ pathLength: 0 }}
        animate={animate}
        transition={{ duration: 1, ease: "easeIn" }}
      />
    </svg>
  );
}

function Line3Reversed({ animate }: Props) {
  return (
    <svg viewBox={"0 0 40 170"}>
      <defs>
        <motion.linearGradient
          id={"line3ReversedGrad"}
          x1={"0%"}
          y1={"0%"}
          x2={"100%"}
          y2={"100%"}
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
        >
          <stop
            offset={"0%"}
            style={{ stopColor: startColor, stopOpacity: 1 }}
          />
          <stop
            offset={"100%"}
            style={{ stopColor: stopColor, stopOpacity: 1 }}
          />
        </motion.linearGradient>
      </defs>

      <motion.path
        d={"m40,0h-15c-2.76,0-5,2.25-5,5.03v160c0,2.75-2.21,4.97-4.94,4.97H0"}
        fill={"none"}
        strokeWidth={"2px"}
        stroke={"url(#line3ReversedGrad)"}
        strokeMiterlimit={10}
        initial={{ pathLength: 0 }}
        animate={animate}
        transition={{ duration: 1, ease: "easeIn" }}
      />
    </svg>
  );
}

function Line4Reversed({ animate }: Props) {
  return (
    <svg viewBox="0 0 65 80">
      <defs>
        <motion.linearGradient
          id={"line4ReversedGrad"}
          x1={"0%"}
          y1={"0%"}
          x2={"100%"}
          y2={"100%"}
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
        >
          <stop
            offset={"0%"}
            style={{ stopColor: stopColor, stopOpacity: 1 }}
          />
          <stop
            offset={"100%"}
            style={{ stopColor: startColor, stopOpacity: 1 }}
          />
        </motion.linearGradient>
      </defs>

      <motion.path
        d={
          "m64,79.5v-34.56c0-2.73-2.17-4.94-4.85-4.94H5.85c-2.68,0-4.85-2.21-4.85-4.94V.5"
        }
        fill={"none"}
        stroke={"url(#line4ReversedGrad)"}
        strokeWidth={"2px"}
        strokeMiterlimit={10}
        initial={{ pathLength: 0 }}
        animate={animate}
        transition={{ duration: 1, ease: "easeIn" }}
      />
    </svg>
  );
}

function Line5Reversed({ animate }: Props) {
  return (
    <svg viewBox="0 0 65 80">
      <defs>
        <motion.linearGradient
          id={"line5ReversedGrad"}
          x1={"0%"}
          y1={"0%"}
          x2={"100%"}
          y2={"100%"}
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
        >
          <stop
            offset={"0%"}
            style={{ stopColor: startColor, stopOpacity: 1 }}
          />
          <stop
            offset={"100%"}
            style={{ stopColor: stopColor, stopOpacity: 1 }}
          />
        </motion.linearGradient>
      </defs>

      <motion.path
        d={
          "m.99,79.5v-34.56c0-2.73,2.17-4.94,4.85-4.94h53.31c2.68,0,4.85-2.21,4.85-4.94V.5"
        }
        fill={"none"}
        stroke={"url(#line5ReversedGrad)"}
        strokeWidth={"2px"}
        strokeMiterlimit={10}
        initial={{ pathLength: 0 }}
        animate={animate}
        transition={{ duration: 1, ease: "easeIn" }}
      />
    </svg>
  );
}
