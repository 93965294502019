import { useMemo } from "react";
import { useLocation, Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";

import logoImg from "assets/home/header/logo.svg";
import { RxHamburgerMenu } from "react-icons/rx";
import { GoSignOut } from "react-icons/go";

import useLogout from "hooks/useLogout";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "store/authSlice";

interface ItemProps {
  id: string;
  name: string;
  link: string;
}

interface NavItemProps {
  item: ItemProps;
}

const navItems: ItemProps[] = [
  { id: "home", name: "Home", link: "/" },
  { id: "team", name: "Team", link: "/team" },
  { id: "investors", name: "Investor Relations", link: "/investor-relations" },
];

function Nav() {
  const { user } = useSelector(selectCurrentAuthData);

  // Hooks
  const navigate = useNavigate();

  // APIs
  const { logout, loggingOut } = useLogout();

  // Responsiveness
  const [isMobileView] = useMediaQuery("(max-width: 1024px)");

  // Handlers
  async function handleLogout() {
    await logout();
  }

  return (
    <Flex
      as={"header"}
      position={"fixed"}
      w={"85%"}
      h={"60px"}
      top={"25px"}
      left={0}
      right={0}
      mx={"auto"}
      bg={"rgba(240, 242, 245, 0.9)"}
      align={"center"}
      justify={"space-between"}
      px={6}
      borderRadius={"30px"}
      boxShadow={"md"}
      backdropFilter={"blur(8px)"}
      zIndex={10}
    >
      {/* logo + name */}
      <Flex
        cursor="pointer"
        align={"center"}
        gap={2}
        onClick={() => navigate("/")}
      >
        {/* logo */}
        <Image src={logoImg} alt="Empathic AI" w={"32px"} objectFit={"cover"} />

        {/* name */}
        <Text
          fontWeight={700}
          fontSize={"18px"}
          style={{
            background: "linear-gradient(90deg, #202053 0.5%, #2088BE 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          EmpathicAI
        </Text>
      </Flex>

      {/* nav items */}
      {isMobileView ? (
        // nav menu for small devices <992px
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<RxHamburgerMenu />}
            variant="outline"
          />
          <MenuList>
            <MenuItem onClick={() => navigate("/")}>Home</MenuItem>
            <MenuItem onClick={() => navigate("/team")}>Team</MenuItem>
            <MenuItem onClick={() => navigate("/investor-relations")}>
              Investor Relations
            </MenuItem>
          </MenuList>
        </Menu>
      ) : (
        // nav menu for large screens devices >991px
        <Flex
          as={"ul"}
          gap={2}
          h={"100%"}
          w={"fit-content"}
          align={"center"}
          justify={"center"}
          listStyleType="none"
          margin="0"
          padding="0"
        >
          {navItems.map((item) => (
            <NavItem item={item} key={item?.id} />
          ))}

          {user && (
            <Button
              py={2}
              px={{ base: 2, sm: 3 }}
              w={"fit-content"}
              h={"fit-content"}
              minH={"34px"}
              fontWeight={"500"}
              fontSize={{ base: "12px", md: "13px", lg: "16px" }}
              color={{ base: "gray.700", lg: "whiteAlpha.900" }}
              bg={"highlight.primary"}
              borderRadius={"18px"}
              letterSpacing={"0.03rem"}
              _hover={{ opacity: 0.8 }}
              _focus={{ opacity: 0.8 }}
              _focusWithin={{ opacity: 1 }}
              _active={{ opacity: 1 }}
              isLoading={loggingOut}
              transition={"all .3s ease"}
              loadingText={"Logging Out"}
              onClick={() => handleLogout()}
            >
              <Icon
                display={"inline-block"}
                fontSize={{ base: "xs", md: "sm", lg: "md" }}
                as={GoSignOut}
                mr={"2"}
              />
              Logout
            </Button>
          )}
        </Flex>
      )}
    </Flex>
  );
}

export default Nav;

function NavItem({ item }: NavItemProps) {
  const { name, link, id } = item;

  // Hooks
  const location = useLocation();

  // active route
  const currentRoute = useMemo(
    () => location?.pathname?.toLowerCase() === link?.toLowerCase(),
    [location, link]
  );

  return (
    <Link
      as={RouterLink}
      to={link}
      id={id}
      p={2.5}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      letterSpacing={currentRoute ? "-0.012rem" : 0}
      border={"none"}
      h={"100%"}
      color={currentRoute ? "#2088BE" : "#202053"}
      fontWeight={currentRoute ? "500" : "400"}
      _hover={{
        textDecoration: "none",
        color: currentRoute ? "#2088BE" : "#202053",
      }}
    >
      <Text fontSize={{ lg: "13px", xl: "14px" }} textTransform={"capitalize"}>
        {name}
      </Text>
    </Link>
  );
}
