import { useState, useEffect, useRef } from "react";
import { Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react";

import AnimatedButton from "shared/AnimatedButton";

import { nodes, sections } from "./helpers";
import { EmboldedHeading } from "components/ui/helpers";
import Node from "./Node";

function Brands() {
  // States
  const [activeSection, setActiveSection] = useState("");
  const sectionsRef = useRef<(HTMLDivElement | null)[]>([]);

  // Handlers
  function scrollToSection(idx: number) {
    const section = sectionsRef?.current[idx];

    if (section) {
      section.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }

  function getText(index: number) {
    return index === 1
      ? "stealth1"
      : index === 2
      ? "stealth2"
      : index === 8
      ? "stealth3"
      : index === 9
      ? "stealth4"
      : nodes[index]?.name ?? "";
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sectionText = entry.target
              .getAttribute("data-text")
              ?.toLowerCase();
            if (sectionText) {
              setActiveSection(sectionText);
            }
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  return (
    <Flex
      direction={"column"}
      h={"fit-content"}
      width={"85%"}
      mx={"auto"}
      my={{ lg: "80px", xl: "120px", "2xl": "140px" }}
    >
      {/* Section Heading */}
      <Heading
        as={"h2"}
        fontFamily={"Poppins, sans-serif"}
        color={"#202053"}
        fontSize={{ lg: "48px", xl: "56px" }}
        fontWeight={"800"}
        letterSpacing={"-2.68359px"}
        lineHeight={{ lg: "66px", "2xl": "76px" }}
      >
        AI Ideas that we are Building
      </Heading>

      {/* Section Content */}
      <Flex gap={4} position={"relative"} h={"fit-content"} w={"100%"}>
        <Flex
          direction={"column"}
          align={"center"}
          justify={"center"}
          w={{ lg: "40%", xl: "50%" }}
        >
          {sections?.map((s, index) => (
            <Flex
              key={s?.name}
              direction={"column"}
              justify={"center"}
              h={"min(700px, 100vh)"}
              w={"fit-content"}
              gap={8}
              ref={(el) => (sectionsRef.current[index] = el)}
              data-text={s?.name}
            >
              <EmboldedHeading
                text={s?.name}
                startColor={s?.startColor}
                stopColor={s?.stopColor}
              />
              <Text
                w={{ lg: "90%", xl: "85%" }}
                fontFamily={"Poppins, sans-serif"}
                fontWeight={"600"}
                fontSize={{ lg: "32px", xl: "46px" }}
                color={"gray.700"}
                lineHeight={1.2}
              >
                {s?.heading}
              </Text>
              <Text w={{ lg: "90%", xl: "85%" }} color={"#425466"}>
                {s?.text}
              </Text>
              {s?.name?.toLowerCase() !== "empathicai" && (
                <AnimatedButton
                  bg={"#0a2540"}
                  color={"gray.200"}
                  fontFamily="Poppins, sans-serif"
                  fontWeight={"500"}
                  fontSize={{ lg: "14px", "2xl": "16px" }}
                  h={"fit-content"}
                  w={"fit-content"}
                  p={3}
                  px={4}
                  mt={6}
                  borderRadius={"30px"}
                  boxShadow={"0px 4px 9px #0e3e5840"}
                  border={"none"}
                  _hover={{ bg: "#0a2540" }}
                >
                  Explore Website
                </AnimatedButton>
              )}
            </Flex>
          ))}
        </Flex>

        {/* graph section */}
        <Flex
          align={"center"}
          justify={"center"}
          w={{ lg: "60%", xl: "50%" }}
          h={"min(700px, 100vh)"}
          position={"sticky"}
          top={0}
        >
          <SimpleGrid
            columns={4}
            columnGap={"40px"}
            rowGap={"80px"}
            templateRows={"repeat(3, auto)"}
            templateColumns={"repeat(4, 90px)"}
            alignItems={"center"}
            justifyItems={"center"}
          >
            {Array.from({ length: 11 }).map((_, index) => (
              <Node
                key={index}
                isCentral={nodes[index]?.name?.toLowerCase() === "empathicai"}
                text={getText(index)}
                altSrc={nodes[index]?.altSrc}
                src={nodes[index]?.src}
                isActive={activeSection === nodes[index]?.name?.toLowerCase()}
                onClick={() =>
                  // scroll to correspondent section
                  scrollToSection(
                    sections.findIndex((s) => s?.name === nodes[index]?.name)
                  )
                }
              />
            ))}
          </SimpleGrid>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Brands;
