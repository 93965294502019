import { Flex } from "@chakra-ui/react";

import QuotedPicture from "./QuotedPicture";
import Content from "./Content";

function OurMission() {
  return (
    <Flex
      align={"center"}
      justify={"center"}
      my={{ lg: "80px", xl: "120px", "2xl": "140px" }}
      w={"85%"}
      mx={"auto"}
      position={"relative"}
      gap={{ xl: 6, "2xl": 10 }}
    >
      {/* left side */}
      <QuotedPicture />

      {/* right side */}
      <Content />
    </Flex>
  );
}

export default OurMission;
