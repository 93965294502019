import { Box, Stack } from "@chakra-ui/react";

const SideAuthSection = () => {
  return (
    <Stack spacing={4} bg={"#7084bc"}>
      <Box h={"100vh"}>
        <video
          src="https://empathicai.s3.eu-central-1.amazonaws.com/auth_screen_video.mp4"
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Box>
    </Stack>
  );
};

export default SideAuthSection;
