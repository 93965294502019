import { Flex, Text } from "@chakra-ui/react";
import stealthVideo from "assets/home/brands/stealth.webm";

interface Props {
  text: string;
}

function LaunchingSoon({ text }: Props) {
  if (!text) return <></>;
  return (
    <Flex
      align={"center"}
      justify={"center"}
      gap={3}
      position={"absolute"}
      top={text === "stealth1" || text === "stealth2" ? "-60px" : "unset"}
      bottom={text === "stealth3" || text === "stealth4" ? "-60px" : "unset"}
      left={"calc(50% -100px)"}
      bg={"white"}
      px={3}
      py={1.5}
      borderRadius={"12px"}
      w={"180px"}
      boxShadow={"md"}
    >
      <Flex
        as={"video"}
        src={stealthVideo}
        w={"32px"}
        aspectRatio={1}
        autoPlay
        muted
      />
      <Text fontSize={"13px"} fontFamily={"Poppins, sans-serif"}>
        Launching soon..
      </Text>
    </Flex>
  );
}

export default LaunchingSoon;
