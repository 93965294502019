import { Heading, Link, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import LoginForm from "components/auth/login/LoginForm";

const LoginPage = () => {
  // Hooks
  const navigate = useNavigate();

  return (
    <>
      <Heading size="md">Welcome back</Heading>
      <Heading size="lg">Sign in to your account</Heading>
      <LoginForm />
      <Text>
        Don't have an account?{" "}
        <Link color="blue.500" onClick={() => navigate("/register")}>
          Sign Up
        </Link>
      </Text>
    </>
  );
};

export default LoginPage;
