import { useState } from "react";

import {
  Box,
  Heading,
  Image,
  Stack,
  Text,
  SimpleGrid,
  Divider,
  Icon,
  Card,
  CardBody,
  CardFooter,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

import { MdArrowOutward } from "react-icons/md";
import { FaLinkedin, FaTimes } from "react-icons/fa";

import owotoki from "assets/team/owotoki.jpeg";
import doe from "assets/team/john_doe.jpeg";

interface PartnerProps {
  name: string;
  title: string;
  imgSrc: string;
  social: string;
  bio: string;
}

const MotionBox = motion(Box);

export default function OurPartners() {
  const [allCardsVisible, setAllCardsVisible] = useState(true);
  const [selected, setSelected] = useState<PartnerProps>();

  const PARTNERS = [
    {
      name: "Peter Owotoki",
      title: "Co-Founder & CEO",
      imgSrc: `${owotoki}`,
      social: "https://www.linkedin.com/in/peter-owotokidr-ing/",
      bio: "Dr. Peter Owotoki is an expert in the field of AI and an experienced entrepreneur of innovative ventures focused on compassionate AI. He has pioneered healthcare solutions and led expert teams towards business success.",
    },
    {
      name: "Martin Dober",
      title: "Co-Founder & COO",
      imgSrc:
        "https://vf-website-assets.s3.eu-central-1.amazonaws.com/martin_doper.jpeg",
      social: "https://www.linkedin.com/in/dober-martin/",
      bio: "Martin Dober is the Co-Founder & COO of empathicAI. He is a seasoned executive with extensive experience in the pharmaceutical and insurance sectors, having held leadership positions at Roche and Zurich Insurance. He is known for his strategic insights into the purchasing practices of large companies and his ability to foster valuable partnerships. Martin’s entrepreneurial mindset and leadership skills are reflected in his ability to cultivate high-performing teams while promoting a workplace culture that emphasizes both productivity and holistic well-being. In his personal life, Martin enjoys exploring the Swiss mountains with his family and regularly swims in Lake Zurich, which he finds both energizing and inspiring.",
    },
    {
      name: "Wamuyu Owotoki",
      title: "Co-Founder & CEO, iZola.life",
      imgSrc:
        "https://vf-website-assets.s3.eu-central-1.amazonaws.com/empathicai/wamuyu.jpeg",
      social: "https://www.linkedin.com/in/wamuyu/",
      bio: "Dr. Wamuyu Owotoki is co-founder and scientific director of Vitafluence.ai and CEO of iZola.life. Her work focuses on telemedicine, AI-based screening, and drug development. She studied pharmacy in St. Petersburg and obtained her doctorate in medicinal chemistry in Hamburg. With 12 years of experience in the pharmaceutical industry, she is also active in volunteering and enjoys experimenting with new nail polish colors.",
    },
    {
      name: "Mary McDavid",
      title: "Co-Founder & Advisory Board",
      imgSrc:
        "https://vf-website-assets.s3.eu-central-1.amazonaws.com/empathicai/wamuyu.jpeg",
      social: "https://www.linkedin.com/in/wamuyu/",
      bio: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque aspernatur temporibus quae, quam itaque id voluptates accusantium consequuntur ab commodi, sit architecto. Aspernatur consectetur, dolores ipsa saepe officia minus iure? Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque aspernatur temporibus quae, quam itaque id voluptates accusantium consequuntur ab commodi, sit architecto. Aspernatur consectetur, dolores ipsa saepe officia minus iure?",
    },
    {
      name: "John Doe",
      title: "Places",
      imgSrc: `${doe}`,
      social: "https://www.linkedin.com/in/wamuyu/",
      bio: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque aspernatur temporibus quae, quam itaque id voluptates accusantium consequuntur ab commodi, sit architecto. Aspernatur consectetur, dolores ipsa saepe officia minus iure? Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque aspernatur temporibus quae, quam itaque id voluptates accusantium consequuntur ab commodi, sit architecto. Aspernatur consectetur, dolores ipsa saepe officia minus iure?",
    },
  ];

  const expandCard = (url: string, partner: PartnerProps) => {
    setAllCardsVisible(false);
    setSelected(partner);
  };

  const goToUrl = (url: string | undefined) => {
    if (url) window.open(url, "_blank");
  };

  return (
    <Box width="100%" maxW="1200px" margin="auto" pt="64px" pb="112px" px={4}>
      <Heading
        as={"h2"}
        fontFamily="Poppins, sans-serif"
        lineHeight="1.3"
        fontSize={{ lg: "22px", xl: "26px", "2xl": "34px" }}
        w={"100%"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {allCardsVisible ? (
          <Text
            bgGradient="linear(to right, rgb(31.51, 32.14, 82.55) 0%, rgb(32, 136, 190) 100%)"
            bgClip="text"
            color="transparent"
          >
            Venture Partners
          </Text>
        ) : (
          <>
            <Text
              bgGradient="linear(to right, rgb(31.51, 32.14, 82.55) 0%, rgb(32, 136, 190) 100%)"
              bgClip="text"
              color="transparent"
              fontStyle={"italic"}
              pr={2}
            >
              {selected?.name.split(" ")[0]}
            </Text>
            <Text
              bgGradient="linear(to right, rgb(31.51, 32.14, 82.55) 0%, rgb(32, 136, 190) 100%)"
              bgClip="text"
              color="transparent"
            >
              {selected?.name.split(" ")[1]}
            </Text>
          </>
        )}
      </Heading>

      <Divider mt={4} />

      {allCardsVisible && (
        <MotionBox
          initial="invisible"
          animate={allCardsVisible ? "visible" : "invisible"}
          variants={{
            visible: { opacity: 1, marginTop: "0" },
            invisible: { opacity: 0.6, marginTop: "0" },
          }}
          transition={{
            ease: "easeIn",
            duration: 0.5,
            delay: 0.2,
          }}
        >
          <SimpleGrid mt={4} columns={[2, null, 4]} spacing="20px">
            {PARTNERS.map((partner: any, index: number) => (
              <Card
                height="fit-content"
                direction="column"
                overflow="hidden"
                variant="outline"
                key={`${partner.name}-${index}`}
                cursor="pointer"
                _hover={{
                  ".card-heading": {
                    color: "#2088BE",
                  },
                }}
                onClick={() => expandCard(partner.social, partner)}
              >
                <Image
                  maxW="100%"
                  objectFit="cover"
                  src={partner.imgSrc}
                  h={["200px", "230px", "275px"]}
                  alt={`${partner.name} Profile Image`}
                  bg={"linear-gradient(to top, #2088BE 2%, transparent)"}
                />

                <Stack>
                  <CardBody>
                    <Heading size="md" className="card-heading">
                      {partner.name}
                    </Heading>
                    <Text py="2" color={"gray.500"}>
                      {partner.title}
                    </Text>
                  </CardBody>
                </Stack>
              </Card>
            ))}
          </SimpleGrid>
        </MotionBox>
      )}

      {!allCardsVisible && (
        <SimpleGrid mt={4} columns={1} spacing="20px">
          <Card direction="row" overflow="hidden" variant="outline">
            <IconButton
              icon={<FaTimes />}
              size={"md"}
              aria-label="Close"
              variant="solid"
              borderRadius="50%"
              position="absolute"
              top="16px"
              right="18px"
              color="highlight.primary"
              borderColor="white"
              bg={"rgba(255, 255, 255, 0.75)"}
              boxShadow={"md"}
              _hover={{
                backgroundColor: "gray.100",
              }}
              onClick={() => setAllCardsVisible(true)}
            />
            <Image
              objectFit="cover"
              w="40%"
              src={selected?.imgSrc}
              alt={`${selected?.name} Profile Image`}
            />

            <MotionBox
              w={"60%"}
              initial="invisible"
              animate={!allCardsVisible ? "visible" : "invisible"}
              variants={{
                visible: { opacity: 1, y: 0 },
                invisible: { opacity: 0.1, y: 48 },
              }}
              transition={{
                ease: "easeIn",
                duration: 0.5,
                delay: 0.3,
              }}
            >
              <Stack w={"100%"}>
                <CardBody mt={"52px"} h={"100%"}>
                  <Text py="2">{selected?.bio}</Text>
                </CardBody>

                <CardFooter>
                  <Button
                    colorScheme="blue"
                    variant="ghost"
                    onClick={() => goToUrl(selected?.social)}
                  >
                    <Icon as={FaLinkedin} /> <Text px={2}>LinkedIn</Text>
                    <Icon as={MdArrowOutward} />
                  </Button>
                </CardFooter>
              </Stack>
            </MotionBox>
          </Card>
        </SimpleGrid>
      )}
    </Box>
  );
}
