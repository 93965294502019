import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "store/authSlice";
import useAllowAccess from "hooks/useAllowAccess";

interface RequireAuthProps {
  allowedTypes: string[];
}

const RequireAuth = ({ allowedTypes }: RequireAuthProps) => {
  const { user } = useSelector(selectCurrentAuthData);
  const { userIsAnAllowedRoles } = useAllowAccess();
  const location = useLocation();

  return user && userIsAnAllowedRoles(allowedTypes) ? (
    <Outlet />
  ) : user ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
