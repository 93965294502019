import { axiosPrivate } from "api/axios";

export const useUsersAPI = () => {
  const signUp = async (payload: {
    email: string;
    password: string;
    accesscode: string;
  }) => {
    const response = await axiosPrivate.post(`/api/v1/register`, payload);
    return response.data;
  };

  const confirmSignUp = async (payload: {
    code: string;
    email: string;
    user_sub: string;
  }) => {
    const response = await axiosPrivate.post(`/api/v1/verify_sign_up`, payload);
    return response.data;
  };

  // Verify Verification Code
  const verifyCode = async (payload: { verificationCode: string }) => {
    const response = await axiosPrivate.post(
      `/api/v1/verify_verification_code`,
      payload
    );
    return response.data;
  };

  const getUsers = async () => {
    const response = await axiosPrivate.get(`/api/v1/users`);
    return response.data;
  };

  const updateUser = async (payload: {
    id: string;
    firstname?: string;
    lastname?: string;
  }) => {
    const response = await axiosPrivate.put(
      `/api/v1/users/${payload.id}`,
      payload
    );
    return response.data;
  };

  const getReferral = async (id: string) => {
    const response = await axiosPrivate.get(`/api/v1/referrals/${id}`);
    return response.data;
  };

  return {
    signUp,
    confirmSignUp,
    verifyCode,
    getUsers,
    updateUser,
    getReferral,
  };
};
