import { Flex, Heading, Text } from "@chakra-ui/react";
import Card from "./Card";

import icon1 from "assets/home/ourMission/icon1.svg";
import icon2 from "assets/home/ourMission/icon2.svg";
import icon3 from "assets/home/ourMission/icon3.svg";

function Content() {
  return (
    <Flex
      w={{ lg: "100%", "2xl": "60%" }}
      h={"100%"}
      justify={"center"}
      direction={"column"}
      p={3}
    >
      {/* sub-heading */}
      <Text
        fontFamily={"Poppins, sans-serif"}
        fontWeight={"600"}
        bgGradient={`linear(to right, #641ba3 0%, #2088be 100%)`}
        bgClip={"text"}
        color={"transparent"}
        textTransform={"uppercase"}
        letterSpacing={"1px"}
        fontSize={{ lg: "14px", "2xl": "16px" }}
        lineHeight={1}
        w={"fit-content"}
      >
        Our Mission
      </Text>

      {/* main heading */}
      <Heading
        fontFamily={"Poppins, sans-serif"}
        color={"#202053"}
        fontSize={{ lg: "54px", xl: "50px", "2xl": "71px" }}
        fontWeight={"800"}
        letterSpacing={"-2.68359px"}
        lineHeight={{ lg: "66px", "2xl": "76px" }}
        maxW={{ lg: "100%", "2xl": "700px" }}
      >
        Research & Create Empathic AI
      </Heading>

      <Flex mt={"42px"} direction={"column"} gap={5}>
        <Card
          icon={icon1}
          heading={"HUMAN-AI SYMBIOSIS"}
          text={
            "AI is amplifying humans to scale the reach and cognitive capabilities of human experts"
          }
        />

        <Card
          icon={icon2}
          heading={"EMPATHETIC USE CASES"}
          text={
            "Focus on helping humans to live healthier and more prosperously"
          }
        />

        <Card
          icon={icon3}
          heading={"ETHICAL & REPRESENTATIVE"}
          text={
            "Ethical representation of human diversity, minimize bias and lead to strong and empathic AI-solutions"
          }
        />
      </Flex>
    </Flex>
  );
}

export default Content;
