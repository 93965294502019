import {
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { axiosClient } from "api/axios";
import { useUsersAPI } from "api/useUsersAPI";
import { useRef, useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import { useDispatch } from "react-redux";
import { setAccessToken, setRefreshToken, setUser } from "store/authSlice";

import { validateEmail, validatePass } from "utils/helpers";

const RegisterForm = () => {
  // States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [accesscode, setAccessCode] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [isPwdVisible, setIsPwdVisible] = useState(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [errorMsg, setErrorMsg] = useState("");

  const [step, setStep] = useState(1);
  const [code, setCode] = useState("");
  const [userSub, setUserSub] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);

  // Hooks
  const toast = useToast();
  const dispatch = useDispatch();

  // APIs
  const { confirmSignUp, signUp } = useUsersAPI();

  // Handlers
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  function handleCodeKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      handleVerify();
    }
  }

  const onSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault();
    if (!isFormValid()) return;

    setSubmitting(true);
    const data = { email, accesscode, password };

    try {
      const res = await signUp(data);
      setUserSub(res.data.UserSub);
      setSubmitting(false);
      setStep(2);
    } catch (error: any) {
      toast({
        description:
          error?.response?.data?.message ||
          "Something went wrong while signing up. Please try again",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleVerify = async () => {
    if (code.length < 6) {
      setErrorMsg("Code needs to be 6 characters long at least");
      return;
    }

    const payload = {
      code: code,
      user_sub: userSub,
      email: email.trim(),
      accesscode,
    };

    setIsVerifying(true);

    try {
      await confirmSignUp(payload);
      toast({
        title: "Success",
        description: "Registration successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setIsVerifying(false);
      loginUser(payload.email, password);
    } catch (error: any) {
      console.log(error);
      const verifyingError = error.response.data.message
        ? error.response.data.message
        : "Something went wrong while confirming sign up";

      setErrorMsg(verifyingError);
      setIsVerifying(false);
    } finally {
      setIsVerifying(false);
    }
  };

  const loginUser = async (username: string, password: string) => {
    try {
      setIsSigningIn(true);

      const response = await axiosClient.post(
        "/api/v1/auth",
        JSON.stringify({ username, password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const { user, accessToken, refreshToken } = response?.data?.data;

      dispatch(setUser(user));
      dispatch(setAccessToken(accessToken));
      dispatch(setRefreshToken(refreshToken));
    } catch (error: any) {
      // No response from server
      if (!error.response) {
        toast({
          description: "No server response!",
          status: "error",
        });
        setIsSigningIn(false);
        return;
      }
      toast({
        description: error.response.data.message,
        status: "error",
      });
      setIsSigningIn(false);
    } finally {
      setIsSigningIn(false);
    }
  };

  const isFormValid = () => {
    if (!validateEmail(email)) {
      setErrorMsg("Please provide a valid email address");
      if (emailRef.current !== null) emailRef.current.focus();
      return false;
    }

    if (validatePass(password).length > 0) {
      setErrorMsg(validatePass(password));
      if (passwordRef.current !== null) passwordRef.current.focus();
      return false;
    }

    setErrorMsg("");
    return true;
  };

  return (
    <>
      {step === 1 ? (
        <form onSubmit={onSubmit}>
          <Stack spacing={4}>
            <FormControl isRequired id="email">
              <FormLabel>Email Address</FormLabel>
              <Input
                type="email"
                ref={emailRef}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Email Address"
                borderWidth={1}
                bg={"background"}
                borderColor={"gray.200"}
                _hover={{ borderColor: "gray.300" }}
                _focusVisible={{ borderColor: "blue.300" }}
              />
            </FormControl>

            <FormControl isRequired id="accesscode">
              <FormLabel>Access Code</FormLabel>
              <Input
                type="text"
                value={accesscode}
                onChange={(e) => setAccessCode(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Referall Code"
                borderRadius={"6px"}
                bg={"background"}
                borderWidth={1}
                borderColor={"gray.200"}
                _hover={{ borderColor: "gray.300" }}
                _focusVisible={{ borderColor: "blue.300" }}
              />
            </FormControl>

            <FormControl isRequired id="password">
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  ref={passwordRef}
                  type={isPwdVisible ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Enter your password"
                  borderRadius={"6px"}
                  bg={"background"}
                  borderWidth={1}
                  borderColor={"gray.200"}
                  _hover={{ borderColor: "gray.300" }}
                  _focusVisible={{ borderColor: "blue.300" }}
                />
                <InputRightElement>
                  <Button
                    onClick={() => setIsPwdVisible(!isPwdVisible)}
                    color={"secondary.300"}
                    size="sm"
                  >
                    {isPwdVisible ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            {!!errorMsg && (
              <Text
                w={"100%"}
                fontSize={"sm"}
                color={"red.500"}
                textAlign={"center"}
                borderRadius={"6px"}
                bg={"rgba(255, 0, 0, .02)"}
                borderStyle={"dashed"}
                borderWidth={1}
                borderColor={"rgba(255, 0, 0, .35)"}
                p={3}
                mt={2}
              >
                {errorMsg}
              </Text>
            )}

            <Button
              type="submit"
              colorScheme="blue"
              mt={2}
              isLoading={submitting}
              loadingText="Processing..."
            >
              Sign Up
            </Button>
          </Stack>
        </form>
      ) : (
        <Stack spacing={4}>
          <FormControl isRequired id="code">
            <FormLabel>Verification Code</FormLabel>
            <Input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              onKeyDown={handleCodeKeyDown}
              placeholder="Enter the verification code"
              borderRadius={"6px"}
              bg={"background"}
              borderWidth={1}
              borderColor={"gray.200"}
              _hover={{ borderColor: "gray.300" }}
              _focusVisible={{ borderColor: "blue.300" }}
            />
            <FormHelperText>
              The verification code has been sent to the email address provided.
            </FormHelperText>
          </FormControl>

          {!!errorMsg && (
            <Text
              w={"100%"}
              fontSize={"sm"}
              color={"red.500"}
              textAlign={"center"}
              borderRadius={"6px"}
              bg={"rgba(255, 0, 0, .02)"}
              borderStyle={"dashed"}
              borderWidth={1}
              borderColor={"rgba(255, 0, 0, .35)"}
              p={3}
              mt={2}
            >
              {errorMsg}
            </Text>
          )}

          <Button
            mt={2}
            type="submit"
            bg={"primary.400"}
            color={"primary.100"}
            onClick={handleVerify}
            isLoading={isVerifying || isSigningIn}
            loadingText={isVerifying ? "Verifying" : "Signing In"}
            _hover={{ bg: "primary.500" }}
          >
            Verify
          </Button>
        </Stack>
      )}
    </>
  );
};

export default RegisterForm;
