import { useEffect } from "react";
import { Flex } from "@chakra-ui/react";

import Header from "components/home/Header";
import Brands from "components/home/brands";
import BackToTop from "components/ui/BackToTop";
import OurMission from "components/home/mission";
import TrustedBy from "components/home/trustedBy";

export default function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex
      direction={"column"}
      position="relative"
      bg={"transparent"}
      w={"100%"}
      pb={"64px"}
    >
      <BackToTop />

      <Header />

      <OurMission />

      <Brands />

      {/* TODO: Team section */}
      <Flex
        borderRadius={"16px"}
        border={"2px solid #2088be"}
        h={"200px"}
        justify={"center"}
        align={"center"}
        fontWeight={"500"}
        color={"#202053"}
        bg={"rgba(23, 74, 228, 0.08)"}
        my={{ lg: "80px", xl: "120px", "2xl": "140px" }}
        w={"85%"}
        mx={"auto"}
      >
        Team section here 👋
      </Flex>

      <TrustedBy />
    </Flex>
  );
}
