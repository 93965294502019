import { Flex, Heading } from "@chakra-ui/react";
import AnimatedButton from "shared/AnimatedButton";
import { TypeAnimation } from "react-type-animation";
import { useNavigate } from "react-router-dom";

function Header() {
  // Hook
  const navigate = useNavigate();

  return (
    <Flex position={"relative"} w={"100%"} h={"100vh"}>
      <Flex position={"absolute"} inset={0}>
        {/* gradient overlay */}
        <Flex
          position={"absolute"}
          inset={0}
          w={"100%"}
          h={"100%"}
          background={"linear-gradient(to top, #6985c1 30%, transparent)"}
          opacity={"0.5"}
          zIndex={1}
        />
        {/* landing video */}
        <Flex
          as={"video"}
          w={"100%"}
          h={"100%"}
          src={
            "https://empathicai.s3.eu-central-1.amazonaws.com/human_robot_hand_video.webm"
          }
          objectFit={"cover"}
          objectPosition={"top center"}
          autoPlay
          muted
          loop
        />
      </Flex>

      {/* typewriter-effect content */}
      <Flex
        direction={"column"}
        gap={10}
        align={"center"}
        justify={"center"}
        zIndex={1}
        w={"100%"}
        h={"100%"}
      >
        <Flex align={"center"} gap={4} justify={"center"} mx={"auto"}>
          <Flex
            pt={2}
            pb={6}
            px={8}
            boxShadow={"0 8px 32px 0 rgba(31.51, 32.14, 82.55, 0.2)"}
            backdropFilter={"blur(6px)"}
            borderRadius={"10px"}
          >
            <Heading
              fontSize={{ lg: "48px", xl: "52px" }}
              as={"h1"}
              textAlign={"center"}
              mx={"auto"}
              color={"blue.800"}
            >
              <TypeAnimation
                sequence={[
                  "EmpathicAI.life",
                  2000,
                  "AI for Life",
                  1000,
                  "AI for Health",
                  1000,
                  "AI for Prosperity",
                  3000,
                  "AI at the Service of Humanity",
                  3000,
                ]}
                speed={60}
                repeat={Infinity}
              />
            </Heading>
          </Flex>
        </Flex>
      </Flex>

      {/* action button */}
      <Flex
        justify={"center"}
        align={"center"}
        w={"fit-content"}
        position={"absolute"}
        mx={"auto"}
        bottom={"min(20%, 100px)"}
        left={"50%"}
        transform={"translateX(-50%)"}
        zIndex={1}
      >
        {/* Contact Us Button */}
        <AnimatedButton
          w={"180px"}
          size={{ lg: "md", xl: "lg" }}
          color={"white"}
          bg={"#205489"}
          boxShadow={"0px 4px 9px 0px #00000040"}
          _hover={{ bg: "#232F41" }}
          _focus={{ bg: "#232F41" }}
          onClick={() => navigate("/investor-relations")}
        >
          Contact us
        </AnimatedButton>
      </Flex>
    </Flex>
  );
}

export default Header;
