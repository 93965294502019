import { useMemo, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { Flex, Image, Text } from "@chakra-ui/react";

import LaunchingSoon from "./LaunchingSoon";

import { hexToRgba } from "utils/helpers";
import { EmboldedHeading } from "components/ui/helpers";
import { getNodeLinksProps, getNodeLinks } from "./helpers";

interface NodeProps {
  text: string;
  src?: string;
  altSrc?: string;
  isCentral?: boolean;
  isActive: boolean;
  onClick: () => void;
}

const MotionText = motion(Text);
const MotionFlex = motion(Flex);

const stealths = ["stealth1", "stealth2", "stealth3", "stealth4"];

function Node({ text, src, altSrc, isCentral, isActive, onClick }: NodeProps) {
  // States
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredStealth, setHoveredStealth] = useState<string | null>(null);

  // Hooks
  const controls = useAnimation();

  const NodeLinks = useMemo(() => getNodeLinks(text), [text]);
  const nodeLinksProps = useMemo(() => getNodeLinksProps(text), [text]);
  const isStealth = stealths.includes(text?.toLowerCase());

  // Handlers
  function isTextLogo(text: string) {
    return text === "Xaidi" || text === "iZola" || text === "NeuroBee";
  }

  function handleMouseEnter() {
    setIsHovered(true);

    const loweredText = text?.toLowerCase();
    if (stealths.includes(loweredText)) {
      setHoveredStealth(loweredText);
    }
  }

  function handleMouseLeave() {
    setIsHovered(false);
    setHoveredStealth(null);
  }

  function handleClick() {
    if (!isStealth) onClick();
  }

  return (
    <Flex
      direction={"column"}
      justify={"center"}
      align={"center"}
      borderRadius={"md"}
      w={isCentral ? "220px" : "90px"}
      h={"90px"}
      gridColumn={isCentral ? "2 / span 2" : "auto"} // "2 / span 2": spans the 2nd and 3rd columns, keeping it in the middle
      transition={"all .3s ease"}
      cursor={isStealth ? "auto" : "pointer"}
      boxShadow={
        isActive || isHovered || isCentral
          ? `${hexToRgba("#0000ff", 0.15)} 0px 10px 36px 0px, ${hexToRgba(
              "#00c1b4",
              0.4
            )} 0px 0px 0px 1px`
          : "none"
      }
      borderWidth={1}
      borderColor={
        isActive || isHovered || isCentral ? "transparent" : "#cbd5e0"
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      position={"relative"}
    >
      {altSrc && (
        <MotionFlex
          as={motion.div}
          position={"relative"}
          align={"center"}
          justify={"center"}
          direction={isCentral ? "row" : "column"}
          gap={isCentral ? 4 : 0}
          w={isCentral ? "fit-content" : "90px"}
          h={"90px"}
          onHoverStart={() => controls.start({ pathLength: 1 })}
          onHoverEnd={() => controls.start({ pathLength: 0 })}
        >
          <Flex
            align={"center"}
            justify={"center"}
            direction={"column"}
            gap={2}
            borderRadius={"md"}
          >
            <Image
              key={altSrc}
              src={isActive || isHovered || isCentral ? src : altSrc}
              alt={text}
              w={isTextLogo(text) ? "53px" : "35px"}
              objectFit="cover"
            />
          </Flex>

          <MotionText
            fontSize={"11px"}
            color={"#425466"}
            fontWeight={"500"}
            fontFamily={"Poppins, sans-serif"}
            position={isCentral ? "relative" : "absolute"}
            opacity={isCentral ? 1 : 0}
            left={0}
            right={0}
            textAlign={"center"}
            animate={{
              bottom: (isActive || isHovered) && !isCentral ? "4px" : "0px",
              opacity: isActive || isHovered || isCentral ? 1 : 0,
            }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
          >
            {isCentral ? (
              <EmboldedHeading text={text} inGraph />
            ) : text?.includes("stealth") ? (
              "Stealth"
            ) : (
              text
            )}
          </MotionText>
        </MotionFlex>
      )}

      {!!NodeLinks &&
        NodeLinks?.length &&
        !!nodeLinksProps &&
        !!nodeLinksProps?.length &&
        NodeLinks?.map((Link: any, idx: number) => {
          const { w, h, top, left } = nodeLinksProps?.at(idx) ?? {};

          return (
            <Flex
              key={idx}
              position={"absolute"}
              w={w}
              h={h}
              top={top}
              left={left}
              zIndex={-1}
            >
              <Link animate={controls} />
            </Flex>
          );
        })}

      <LaunchingSoon text={hoveredStealth ?? ""} />
    </Flex>
  );
}

export default Node;
