import { Text } from "@chakra-ui/react";

interface EmboldedTextProps {
  text: string;
  isSmall?: boolean;
  inGraph?: boolean;
  startColor?: string;
  stopColor?: string;
}

export function EmboldedHeading({
  text,
  inGraph,
  startColor = "#202053",
  stopColor = "#2088be",
}: EmboldedTextProps) {
  return (
    <Text
      bgGradient={`linear(to right, ${startColor} 0%, ${stopColor} 100%)`}
      bgClip={"text"}
      color={"transparent"}
      fontFamily={"Poppins, sans-serif"}
      lineHeight={"1.3"}
      fontSize={{
        lg: inGraph ? "14px" : "18px",
        xl: inGraph ? "16px" : "20px",
        "2xl": inGraph ? "18px" : "22px",
      }}
      fontWeight={"600"}
      w={"fit-content"}
    >
      {text}
    </Text>
  );
}
