import { useDispatch, useSelector } from "react-redux";
import { clearCredentials, selectCurrentAuthData } from "store/authSlice";
import { axiosClient } from "api/axios";
import { Box, useToast } from "@chakra-ui/react";
import { useState } from "react";

export default function useLogout() {
  // State
  const [loggingOut, setLoggingOut] = useState(false);

  // Hooks
  const dispatch = useDispatch();
  const toast = useToast();

  const { refreshToken } = useSelector(selectCurrentAuthData);

  const logout = async () => {
    try {
      setLoggingOut(true);

      await axiosClient.post("/api/v1/logout", {
        refreshToken,
      });

      toast({
        position: "bottom-right",
        duration: 1500,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Logged out successfully
          </Box>
        ),
      });
    } catch (error) {
      toast({
        position: "bottom-right",
        duration: 1500,
        render: () => (
          <Box color={"white"} p={3} bg={"red"} borderRadius={"6px"}>
            Failed to log out, try again
          </Box>
        ),
      });
    } finally {
      dispatch(clearCredentials());
      setLoggingOut(false);
    }
  };

  return { logout, loggingOut };
}
