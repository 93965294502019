import { Box, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

interface PdfPreviewProps {
  fileUrl: string;
  scale?: number;
  previewHeight: number;
  customLoading?: React.ReactNode;
  setLoading?: (isLoading: boolean) => void;
}

const PdfPreview = ({
  fileUrl,
  previewHeight,
  customLoading,
  setLoading,
}: PdfPreviewProps) => {
  const [numPages, setNumPages] = useState<number | undefined>();

  useEffect(() => {
    if (setLoading) setLoading(true);
    return () => {
      if (setLoading) setLoading(false);
    };
  }, [setLoading]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
    if (setLoading) setLoading(false);
  }

  return (
    <Box height={`${previewHeight}px`} overflow="hidden">
      <Document
        file={fileUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={customLoading || <Spinner size="xl" />}
      >
        <Page pageNumber={1} />
      </Document>
      <Text visibility={"hidden"}>
        Page {1} of {numPages || "N/A"}
      </Text>
    </Box>
  );
};

export default PdfPreview;
