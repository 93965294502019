import { useEffect, useState } from "react";
import { animate, motion, useMotionValue } from "framer-motion";
import useMeasure from "react-use-measure";

import { Box, Flex, Heading, Image } from "@chakra-ui/react";

import askLePoisLogo from "assets/home/companies/asklepois.svg";
import awsLogo from "assets/home/companies/aws.svg";
import googleLogo from "assets/home/companies/google.svg";
import nvidiaLogo from "assets/home/companies/nvidia.svg";
import uclLogo from "assets/home/companies/ucl.svg";
import sidraLogo from "assets/home/companies/sidra.png";
import kemriLogo from "assets/home/companies/kemri.svg";
import pathLogo from "assets/home/companies/path.svg";
import csirLogo from "assets/home/companies/csir.svg";
import aachenLogo from "assets/home/companies/aachen.svg";
import daystarLogo from "assets/home/companies/daystar.png";

export interface CompanyProps {
  src: string;
  alt: string;
}

export const companiesList: CompanyProps[] = [
  { src: awsLogo, alt: "AWS" },
  { src: googleLogo, alt: "Google" },
  { src: nvidiaLogo, alt: "Nvidia" },
  { src: uclLogo, alt: "UCL" },
  { src: sidraLogo, alt: "Sidra" },
  { src: kemriLogo, alt: "Kemri" },
  { src: askLePoisLogo, alt: "Asklepois" },
  { src: aachenLogo, alt: "Aachen University" },
  { src: daystarLogo, alt: "Daystar University" },
  { src: csirLogo, alt: "CSIR" },
  { src: pathLogo, alt: "PATH Org." },
];

export default function TrustedBy() {
  const FAST_DURATION = 25;
  const SLOW_DURATION = 75;

  const [duration, setDuration] = useState(FAST_DURATION);
  let [ref, { width }] = useMeasure();

  const xTranslation = useMotionValue(0);

  const [mustFinish, setMustFinish] = useState(false);
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    let controls;
    const finalPosition = -width / 2 - 5;

    if (mustFinish) {
      controls = animate(xTranslation, [xTranslation.get(), finalPosition], {
        ease: "linear",
        duration: duration * (1 - xTranslation.get() / finalPosition),
        onComplete: () => {
          setMustFinish(false);
          setRerender(!rerender);
        },
      });
    } else {
      controls = animate(xTranslation, [0, finalPosition], {
        ease: "linear",
        duration: duration,
        repeat: Infinity,
        repeatType: "loop",
        repeatDelay: 0,
      });
    }

    return controls?.stop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rerender, xTranslation, duration, width]);

  return (
    <Flex
      direction={"column"}
      gap={10}
      maxW={{ lg: "920px", xl: "1100px", "2xl": "1160px" }}
      mx={"auto"}
    >
      {/* Section Heading */}
      <Heading
        as={"h2"}
        bgGradient="linear(to right, rgb(31.51, 32.14, 82.55) 0%, #2088BE 100%)"
        bgClip="text"
        color="transparent"
        fontFamily="Poppins, sans-serif"
        lineHeight="1.3"
        fontSize={{ lg: "22px", xl: "26px", "2xl": "34px" }}
        w={"fit-content"}
        mx={"auto"}
      >
        Trusted By
      </Heading>

      {/* Section Content */}
      <Flex
        w={"100%"}
        mx={{ base: "auto", lg: 0 }}
        maxW={{ base: "100%", sm: "95%", md: "650px", lg: "100%" }}
        overflow={"hidden"}
      >
        <Box
          as={motion.div}
          style={{
            x: xTranslation,
            display: "flex",
            gap: 0,
            padding: 0,
            margin: 0,
          }}
          ref={ref}
          onHoverStart={() => {
            setMustFinish(true);
            setDuration(SLOW_DURATION);
          }}
          onHoverEnd={() => {
            setMustFinish(true);
            setDuration(FAST_DURATION);
          }}
        >
          {[...companiesList, ...companiesList].map((cmp, idx) => (
            <Company src={cmp?.src} alt={cmp?.alt} key={idx} />
          ))}
        </Box>
      </Flex>
    </Flex>
  );
}

function Company({ src, alt }: CompanyProps) {
  return (
    <Flex
      position="relative"
      overflow="hidden"
      h={{ base: "120px", sm: "130px", md: "140px", lg: "160px" }}
      minW={{ base: "120px", sm: "130px", md: "140px", lg: "160px" }}
      justify="center"
      align="center"
      px={"20px"}
      filter="brightness(0) saturate(100%) invert(66%) sepia(18%) saturate(276%) hue-rotate(214deg) brightness(88%) contrast(92%)"
    >
      <Image src={src} alt={alt} objectFit={"cover"} />
    </Flex>
  );
}
