import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

export interface User {
  id: string;
  sub: string;
  email: string;
  firstname?: string;
  lastname?: string;
}

export interface AuthState {
  user: User | null;
  accessToken: string;
  refreshToken: string;
}

const ref = localStorage.getItem("refreshToken");
let refreshToken = "";

if (ref) {
  try {
    refreshToken = JSON.parse(ref);
  } catch (error) {
    localStorage.removeItem("refreshToken");
  }
}

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    accessToken: "",
    refreshToken,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("loggedIn", JSON.stringify(true));
      localStorage.setItem("userSub", JSON.stringify(action.payload.sub));
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
      localStorage.setItem("refreshToken", JSON.stringify(action.payload));
    },
    clearCredentials: (state) => {
      state.user = null;
      state.accessToken = "";
      state.refreshToken = "";
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("loggedIn");
      localStorage.removeItem("userSub");
    },
    updateUserAttributes: (state, action) => {
      const newUserAttributes = action.payload;
      const prevAttr = state.user || {};
      state.user = { ...prevAttr, ...newUserAttributes };
    },
  },
});

export const {
  setUser,
  clearCredentials,
  updateUserAttributes,
  setAccessToken,
  setRefreshToken,
} = authSlice.actions;

export default authSlice.reducer;

const selectAuthState = (state: { auth: AuthState }) => state.auth;

export const selectCurrentAuthData = createSelector(
  [selectAuthState],
  (auth: any) => ({
    user: auth.user,
    accessToken: auth.accessToken,
    refreshToken: auth.refreshToken,
  })
);
