import { Heading, Link, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import RegisterForm from "components/auth/register/RegisterForm";

const RegisterPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Heading size="md">Welcome</Heading>
      {/* <Heading size="lg">Create your account</Heading> */}
      <Heading size="lg">Register</Heading>
      <RegisterForm />
      <Text>
        Already have an account?{" "}
        <Link color="blue.500" onClick={() => navigate("/login")}>
          Login
        </Link>
      </Text>
    </>
  );
};

export default RegisterPage;
