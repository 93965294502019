import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, Flex, Image, Link, Stack, Text } from "@chakra-ui/react";

import SideAuthSection from "./SideAuthSection";
import logoImg from "assets/home/header/logo.svg";

import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "store/authSlice";

const AuthLayout = () => {
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();
  
  const currentYear = new Date().getFullYear();
  const { user } = useSelector(selectCurrentAuthData);
  const from = location.state?.from || null;
  
  useEffect(() => {
    let redirectTo = "/";
    if (from?.pathname?.length > 0 && from?.search?.length > 0) {
      redirectTo = from.pathname + from.search;
    } else if (from?.pathname?.length > 0 && from?.search?.length === 0) {
      redirectTo = from.pathname;
    }
    if (user) navigate(redirectTo, { replace: true });
  }, [from, navigate, user]);

  return (
    <Flex direction={{ base: "column", md: "row" }} h="100vh">
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        flexDirection={"row"}
        justifyContent={"center"}
        w={{ base: "100%", md: "40%" }}
        p={8}
        mt={12}
      >
        <Stack spacing={6} w={"70%"}>
          <Flex
            gap={2}
            align={"center"}
            fontSize={{ lg: "12px", xl: "14px" }}
            fontWeight={"500"}
          >
            <Image
              w={"32px"}
              src={logoImg}
              cursor={"pointer"}
              alt="Empathic AI"
              objectFit={"cover"}
              onClick={() => navigate("/")}
            />
          </Flex>

          <Outlet />

          <Text fontSize="sm" pt={12}>
            &copy;{currentYear}, All Rights Reserved. <br />
            <Link color="blue.500" onClick={() => navigate("")}>
              Terms of Service
            </Link>{" "}
            |{" "}
            <Link color="blue.500" onClick={() => navigate("")}>
              Privacy Policy
            </Link>
          </Text>
        </Stack>
      </Box>

      <Box w={{ base: "100%", md: "60%" }} bg="gray.100">
        <SideAuthSection />
      </Box>
    </Flex>
  );
};

export default AuthLayout;
