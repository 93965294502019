import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  InputGroup,
  ListIcon,
  ListItem,
  Progress,
  Text,
  Tooltip,
  UnorderedList,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { KeyboardEvent, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "store/authSlice";
import { truncateString } from "utils/helpers";
import { MdClose } from "react-icons/md";
import {
  handleDragFiles,
  handleDropFiles,
  handleFileChange,
  handleFileValidation,
  handleRemoveFile,
  handleUploadFiles,
  handleViewFile,
} from "./helpers";
import FileUploader from "./FileUploader";
import { FiUpload } from "react-icons/fi";
import { CiCircleCheck } from "react-icons/ci";
import PdfPreview from "shared/PdfPreview";
import Spinner from "components/ui/SpinnerDots";

interface FieldProps<T> {
  value: T;
  invalid: boolean;
  error: string;
}

export interface FormDataProps {
  files: FieldProps<any[]>;
  user_id: string;
}

const MAX_TOTAL_SIZE = 10; // 10MBs

export default function Review() {
  // Hooks
  const toast = useToast();
  const axiosPrivate = useAxiosPrivate();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { user } = useSelector(selectCurrentAuthData);

  // States
  const [submitted, setSubmitted] = useState(user?.form_url ? true : false);
  // TODO: Persist form URL, to avoid page reload
  const [submittedForm, setSubmittedForm] = useState(
    user?.form_url ? user.form_url : ""
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [formData, setFormData] = useState<FormDataProps>({
    files: { value: [], invalid: false, error: "" },
    user_id: user?.id,
  });

  // Theme
  const { colors } = useTheme();

  const renderFiles = (files: File[]) => {
    return files.map((file: File, index: number) => (
      <UnorderedList styleType="none" key={`listed-file-${index}`}>
        <ListItem key={index} mb="20px" display="flex">
          <Tooltip
            label={file.name}
            placement={"bottom"}
            bg={"gray.900"}
            color={"gray.100"}
            hasArrow
            py={2}
            px={3}
            m={2}
            maxW={["480px"]}
            borderRadius={"6px"}
            fontSize={"12px"}
            boxShadow={"none"}
            arrowSize={8}
          >
            <Text color={colors.blue[600]} onClick={() => handleViewFile(file)}>
              {truncateString(file.name, 60)}
            </Text>
          </Tooltip>
          <ListIcon
            as={MdClose}
            color={colors.red[500]}
            ml="20px"
            cursor={"pointer"}
            onClick={() => handleRemoveFile(file.name, formData, setFormData)}
          />
        </ListItem>
      </UnorderedList>
    ));
  };

  return (
    <Box>
      <Heading
        as={"h2"}
        fontFamily="Poppins, sans-serif"
        lineHeight="1.3"
        fontSize={{ lg: "22px", xl: "26px", "2xl": "34px" }}
        w={"100%"}
        display={"flex"}
        mb={6}
      >
        <Text
          bgGradient="linear(to right, rgb(31.51, 32.14, 82.55) 0%, rgb(32, 136, 190) 100%)"
          bgClip="text"
          color="transparent"
        >
          Review & Submit
        </Text>
      </Heading>

      {submitted && (
        <Flex
          direction={"column"}
          bg={"background"}
          w={"100%"}
          h={"fit-content"}
        >
          {!isLoading && (
            <Flex mb={4} align={"center"} justify={"space-between"}>
              <Text
                color={"green.500"}
                display="inline-flex"
                alignItems="center"
                fontWeight={500}
              >
                <CiCircleCheck style={{ marginRight: "4px" }} /> Form Submitted
              </Text>
            </Flex>
          )}
          <PdfPreview
            fileUrl={submittedForm}
            previewHeight={600}
            customLoading={<Spinner />}
            setLoading={setIsLoading}
          />
        </Flex>
      )}

      {!submitted && (
        <Box
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter")
              handleUploadFiles(
                axiosPrivate,
                formData,
                setFormData,
                setSubmitting,
                setSubmitted,
                setSubmittedForm,
                setUploadProgress,
                toast,
                MAX_TOTAL_SIZE
              );
          }}
          onDragEnter={(e) => handleDragFiles(e, setDragActive)}
          onDragLeave={(e) => handleDragFiles(e, setDragActive)}
          onDragOver={(e) => handleDragFiles(e, setDragActive)}
        >
          <Text color={"gray.600"} my={6}>
            This is the last step
          </Text>

          <Text
            color={"gray.600"}
            fontStyle={"italic"}
            fontSize={"14px"}
            mb={6}
          >
            Upload the signed form below. PDF only.
          </Text>

          {!dragActive && (
            <FileUploader
              dragActive={dragActive}
              valid={formData.files.invalid}
              accept="application/pdf"
              handleFileChange={(e) =>
                handleFileChange(e, (files) =>
                  handleFileValidation(files, formData, setFormData)
                )
              }
              onButtonClick={() => inputRef.current?.click()}
              error={formData.files.error}
              handleDrag={(e) => handleDragFiles(e, setDragActive)}
              handleDrop={(e) =>
                handleDropFiles(e, setDragActive, (files) =>
                  handleFileValidation(files, formData, setFormData)
                )
              }
            >
              <Text color={colors.white}>Upload</Text>
            </FileUploader>
          )}

          {dragActive && (
            <Box
              p="40px"
              w="100%"
              my="14px"
              borderRadius="10px"
              borderStyle="dotted"
              borderColor={"highlight.primary"}
              borderWidth={3}
              backgroundColor={colors.gray[100]}
              justifyContent="center"
              textAlign="center"
              onDragEnter={(e) => handleDragFiles(e, setDragActive)}
              onDragLeave={(e) => handleDragFiles(e, setDragActive)}
              onDragOver={(e) => handleDragFiles(e, setDragActive)}
              onDrop={(e) =>
                handleDropFiles(e, setDragActive, (files: any) =>
                  handleFileValidation(files, formData, setFormData)
                )
              }
              position="relative"
            >
              <Text fontSize="16px" fontWeight="500" color={colors.gray[500]}>
                Drop the signed form here
              </Text>
              <Text fontSize="16px" color="highlight.primary">
                or
              </Text>
              <FormControl w="100%" mt="14px" textAlign={"center"}>
                <InputGroup
                  id="file-upload"
                  justifyContent={"center"}
                  width={"fit-content"}
                  justifyItems={"center"}
                  margin={"0 auto"}
                >
                  <Input
                    style={{
                      width: "100%",
                      height: "50px",
                      opacity: 0,
                      position: "absolute",
                      top: 0,
                      zIndex: 1,
                      cursor: "pointer",
                    }}
                  />
                  <Button
                    zIndex={0}
                    backgroundColor="highlight.primary"
                    leftIcon={<FiUpload color={colors.white} />}
                    color={"white"}
                  >
                    Upload
                  </Button>
                </InputGroup>
                <Text
                  textAlign={"center"}
                  alignSelf={"center"}
                  color={"gray.400"}
                  mt={"20px"}
                  fontSize={"14px"}
                >
                  Upload the filled and signed copy of the investor relations
                  form
                </Text>
              </FormControl>
            </Box>
          )}

          {formData.files.value.length > 0 && renderFiles(formData.files.value)}

          {uploadProgress > 0 && formData.files.value.length > 0 && (
            <Flex
              flexDirection={"row"}
              justifyContent={"space-between"}
              marginTop={30}
            >
              <Progress
                width={"92%"}
                marginTop={"5px"}
                marginRight={"20px"}
                borderRadius={5}
                size="md"
                hasStripe
                value={uploadProgress}
              />
              <Text
                alignSelf={"center"}
                fontWeight={"500"}
                fontSize={"14px"}
                letterSpacing={".05rem"}
                width={"8%"}
                textAlign={"right"}
              >
                {uploadProgress}%
              </Text>
            </Flex>
          )}

          <Button
            onClick={() =>
              handleUploadFiles(
                axiosPrivate,
                formData,
                setFormData,
                setSubmitting,
                setSubmitted,
                setSubmittedForm,
                setUploadProgress,
                toast,
                MAX_TOTAL_SIZE
              )
            }
            colorScheme="blue"
            mt={4}
            py={"10px"}
            px={{ base: 2, sm: 3 }}
            w={"fit-content"}
            h={"fit-content"}
            fontWeight={"500"}
            fontSize={{ base: "12px", md: "13px", lg: "16px" }}
            color={{ base: "gray.700", lg: "whiteAlpha.900" }}
            bg={"highlight.primary"}
            borderRadius={"18px"}
            letterSpacing={"0.03rem"}
            _hover={{ opacity: 0.8 }}
            _focus={{ opacity: 0.8 }}
            _focusWithin={{ opacity: 1 }}
            _active={{ opacity: 1 }}
            transition={"all .3s ease"}
            isLoading={submitting}
            loadingText="Submitting..."
            disabled={formData.files.error !== ""}
          >
            Submit
          </Button>
        </Box>
      )}
    </Box>
  );
}
